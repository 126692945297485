import React, { useState, useEffect, useRef } from "react";
import "./total.css";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { user_id } from "../redux/userSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import CountUp from "react-countup";
import Lottie from "lottie-react";
import animationData from "../images/Ticketsanimation.json";
import DatePicker from "react-datepicker";
import {
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledPopover,
  Popover,
  PopoverBody,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  BlockBetween,
  Button,
  PreviewCard,
  UserAvatar,
} from "../components/Component";
import { Card } from "reactstrap";
import { Line } from "react-chartjs-2";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import UserAccessMetrics from "../components/partials/default/UserAccessMetrics";
import { PreviewAltCard } from "../components/Component";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import TopPurposeChart from "../components/partials/default/TopPurposesMetrics";
import * as echarts from "echarts";

//Export packages
import html2canvas from "html2canvas";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import "jspdf-autotable";

//Images
import tickets1 from "../../src/images/greenticketnew.png";
import tickets2 from "../../src/images/orangeticket.png";
import tickets3 from "../../src/images/totaltickets.png";
// import tickets4 from "../../src/images/redticket.png";
import tickets4 from "../../src/images/orangeticketnew.png";
import tickets5 from "../../src/images/expiredtickets.png";
import tickets6 from "../../src/images/redticket2.png";
import tickets7 from "../../src/images/lightblueticket.png";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { username, role } from "../redux/userSlice";
import TicketStatusMetrics from "../components/partials/default/TicketStatusMetrics";
import { ResponsivePie } from "@nivo/pie";
import { useHistory } from "react-router-dom";

const Home = ({ state }) => {
  const date = new Date();
  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - 7);
  const client_id = useSelector(user_id);
  // const clientValueId = useSelector(selectSavedValue);
  const clientValueId = localStorage.getItem("value"); //To avoid passing client_id = 14 initially for all clients
  // const clientValueLabel = useSelector(selectSavedLabel);
  const clientValueLabel = localStorage.getItem("label");
  const userrole = useSelector(role);

  const history = useHistory();

  const user = useSelector(username);
  const [clientname, setClientname] = useState("");
  const logoUrl = process.env.REACT_APP_SIGN_IN;

  const [totalUsers, setTotalUsers] = useState(0);
  const [UserData, setUserData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // const [finalLoading, setFinalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState(null);

  const [purchaseTot, setPurchaseTot] = useState(0);
  const [newTot, setNewTot] = useState(0);
  const [returnTot, setReturnTot] = useState(0);

  const [chartData, setChartData] = useState({});
  const [startDate, setStartDate] = useState(new Date()); // Default start date
  const [endDate, setEndDate] = useState(new Date()); // Default end date
  const [dateRanges, setDateRanges] = useState({ start: daysAgo, end: new Date() });
  const [overViewTicket, setOverviewTicket] = useState({
    no_show: 0,
    total_length: 0,
    trips_booked: 0,
    trips_cancelled: 0,
    trips_completed: 0,
  });
  const [overViewFixedTicket, setOverviewFixedTicket] = useState({
    total_length: 0,
    trips_booked: 0,
    trips_activated: 0,
    trips_completed: 0,
  });
  const onRangeChange2 = (dates) => {
    const [start, end] = dates;
    setDateRanges({ start: start, end: end });
    setData([]);
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setDateRanges({ start: start, end: end });
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get("https://ecolane-api.zig-web.com/api/User/GetAnalyticsV3?client_id=1");
      return response.data;
    };
    // setLoading(false);
    getData()
      .then((res) => {
        // setLoading(true);
        // setFinalLoading(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    const getTotCount = async () => {
      try {
        let response;
        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}purchased/usercount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&clientId=${clientValueId}`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}purchased/usercount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&clientId=${client_id}`
          );
        }

        if (response.status === 200) {
          setPurchaseTot(response.data.totalCount);
          setNewTot(response.data.recurringUsers);
          setReturnTot(response.data.oldUsers);
        } else {
          setPurchaseTot(0);
          setReturnTot(0);
          setNewTot(0);
        }
      } catch {
        setPurchaseTot(0);
        setReturnTot(0);
        setNewTot(0);
      }
    };
    getTotCount();
  }, [dateRanges.end, clientValueLabel]);

  const midIndex = Math.floor(UserData.length / 2);
  const firstHalf = UserData.slice(0, midIndex);
  const secondHalf = UserData.slice(midIndex);
  const sum = UserData.reduce((acc, curr) => acc + curr, 0);
  const firstHalfPercentage = ((firstHalf.reduce((acc, curr) => acc + curr, 0) / sum) * 100).toFixed(2);
  const secondHalfPercentage = ((secondHalf.reduce((acc, curr) => acc + curr, 0) / sum) * 100).toFixed(2);
  const [ticketCounts, setTicketCounts] = useState([]);
  const [ticketCounts2, setTicketCounts2] = useState([]);
  const [ticketCounts3, setTicketCounts3] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last Week");

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = ticketCounts.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const redirectToTickets = (name, value) => {
    history.push("/microtickets", {
      purpose: name,
      count: value,
      startDate: dateRanges.start,
      endDate: dateRanges.end,
    });
  };

  const redirectToUsers = (date, value, key) => {
    if (key === "regusers") {
      history.push("/users", {
        date: new Date(date),
        count: value,
        key: "regusers",
      });
    } else if (key === "purchasedusers") {
      history.push("/users", {
        date: new Date(date),
        count: value,
        key: "purchasedusers",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    } else if (key === "returnusers") {
      history.push("/users", {
        date: new Date(date),
        count: value,
        key: "returnusers",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    } else if (key === "newusers") {
      history.push("/users", {
        date: new Date(date),
        count: value,
        key: "newusers",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    }
  };

  const redirectToTotalUsers = (startDate, endDate, key) => {
    if (key === "reguserstot") {
      history.push("/users", {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "reguserstot",
      });
    } else if (key === "purchaseduserstot") {
      history.push("/users", {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "purchaseduserstot",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    } else if (key === "returnuserstot") {
      history.push("/users", {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "returnuserstot",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    } else if (key === "newuserstot") {
      history.push("/users", {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "newuserstot",
        clientID: userrole === 3 && clientValueId ? clientValueId : client_id,
      });
    }
  };

  const redirectToAndIOSUsers = (key, platform, startDate, endDate) => {
    if (key === "Android") {
      history.push("/users", {
        key: "Android",
        platform: platform,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    } else if (key === "iOS") {
      history.push("/users", {
        key: "iOS",
        platform: platform,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }
  };

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (value) {
      case "Today":
        startDate = today;
        endDate = today;
        break;
      case "Last Week":
        startDate.setDate(today.getDate() - 7);
        break;
      case "Last Month":
        startDate.setMonth(today.getMonth() - 1);
        break;
      case "Last 3 Months":
        startDate.setMonth(today.getMonth() - 3);
        break;
      default:
        break;
    }
    setDateRanges({ start: startDate, end: endDate });
  };

  //Tickets Summary
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url;
        if (userrole === 3 && clientValueId) {
          url = `${process.env.REACT_APP_CONFIG_BASE_URL}microtransit/overview?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`;
        } else {
          url = `${process.env.REACT_APP_CONFIG_BASE_URL}microtransit/overview?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`;
        }

        const response = await axios.get(url);
        if (response.status === 200) {
          setOverviewTicket(response.data);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
        // Handle error state if needed
      }
    };

    fetchData();
  }, [dateRanges.start, dateRanges.end, client_id, clientValueId, userrole]);

  //Fixed route summary
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url;
        if (userrole === 3 && clientValueId) {
          url = `${process.env.REACT_APP_CONFIG_BASE_URL}fixedroute/overview?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`;
        } else {
          url = `${process.env.REACT_APP_CONFIG_BASE_URL}fixedroute/overview?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`;
        }

        const response = await axios.get(url);
        if (response.status === 200) {
          setOverviewFixedTicket(response.data);
        }
      } catch (error) {
        console.error("Error fetching ticket history:", error);
        // Handle error state if needed
      }
    };

    fetchData();
  }, [dateRanges.start, dateRanges.end, client_id, clientValueId, userrole]);

  useEffect(() => {
    console.log("Start Date:", dateRanges.start, "End Date:", dateRanges.end);
  }, [dateRanges.start, dateRanges.end]);
  // useEffect(() => {
  //   const fetchTicketCounts = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseTicketCount?startDate=${formatDate(
  //           dateRanges.start
  //         )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
  //       );
  //       const data = await response.json();
  //       setTicketCounts(data.data);
  //       setLoading2(false);
  //     } catch (error) {
  //       console.error("Error fetching ticket counts:", error);
  //     }
  //   };
  //   fetchTicketCounts();
  // }, [dateRanges.start, dateRanges.end]);

  const [loading, setLoading2] = useState(true);
  const LoadingSpinner = () => (
    <div className="text-center mt-3">
      <Spinner color="primary" />
    </div>
  );
  const [totalCount2, setTotalCount2] = useState(0);
  const [count2, setCount2] = useState(0);
  const [dates2, setDates2] = useState([]);
  const [numberOfDates2, setNumberOfDates2] = useState(0); // State for the number of dates
  useEffect(() => {
    const fetchData = async () => {
      if (!dateRanges.start || !dateRanges.end) return;
      try {
        let response;
        if (userrole === 3 && clientValueId) {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurchasedUserCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurchasedUserCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
          );
        }

        const data = await response.json();
        const dates = data.data.map((entry) => entry.date);
        const userCounts = data.data.map((entry) => parseInt(entry.usersCount));
        const totalCount2 = data.data.reduce((acc, entry) => acc + parseInt(entry.usersCount), 0);
        const count2 = data.data.length;
        const numberOfDates2 = dates.length; // Calculate the number of dates
        setTicketCounts2(data.data);
        setLoading2(false); // Update loading state once data is fetched
        setTotalCount2(totalCount2);
        setCount2(count2);
        setDates(dates);
        setNumberOfDates2(numberOfDates2); // Set the number of dates
        setChartData({
          labels: dates,
          datasets: [
            {
              label: "User Count",
              data: userCounts,
              backgroundColor: "#A378D2",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [clientValueId, dateRanges.start, dateRanges.end]);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [dates, setDates] = useState([]);
  const [numberOfDates, setNumberOfDates] = useState(0); // State for the number of dates
  //Farenames
  const [fareNames, setFareNames] = useState([]);
  const [fareNamesNew, setFareNamesNew] = useState([]);
  const [fareCounts, setFareCounts] = useState([]);
  const [tableDates, setTableDates] = useState([]);
  const [fareData, setFareData] = useState([]);
  const [totalFares, setTotalFares] = useState(0);
  const [selectedFare, setSelectedFare] = useState("");
  //Default data:
  // const defaultDates = ['March 20, 2024', 'March 21, 2024', 'March 22, 2024', 'March 23, 2024', 'March 24, 2024', 'March 25, 2024', 'March 26, 2024']
  const [defaultDates, setDefaultDates] = useState([]);
  const [totalActivatedTickets, setTotalActivatedTickets] = useState(0);
  const [totalValidatedTickets, setTotalValidatedTickets] = useState(0);
  const [totalExpiredTickets, setTotalExpiredTickets] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [topPurposesData, setTopPurposesData] = useState(null);
  const [androidIOSData, setAndroidIOSData] = useState(null);
  const chartRef = useRef(null);

  const handleSelectChange = (e) => {
    const selectedFareName = e.target.value;
    setSelectedFare(selectedFareName);
  };

  const stackBarData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    stacked: true,
    dataUnit: "USD",

    datasets: [
      {
        label: "Income",
        backgroundColor: "rgba(157, 114, 255, 0.8)",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
      },
      {
        label: "Expense",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: "#f4aaa4",
        data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125],
      },
    ],
  };

  useEffect(() => {
    const fetchTicketCounts = async () => {
      try {
        let response;
        if (userrole === 3 && clientValueId) {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseTicketCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseTicketCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
          );
        }

        const data = await response.json();
        calculateTotalValues(data.data);

        const sortedData = data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTicketCounts(sortedData);
        // setLoading(false);
      } catch (error) {
        //Reset chart data
        setTicketCounts([]);
        // setData2(null);
        // setTotalCount(0);
        console.error("Error fetching ticket counts:", error);
      }
    };
    fetchTicketCounts();
  }, [clientValueId, dateRanges.start, dateRanges.end]);

  useEffect(() => {
    const fetchTopPurposesData = async () => {
      try {
        // const response = await fetch(
        //   `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurposeCount?startDate=${formatDate(
        //     dateRanges.start
        //   )}&endDate=${formatDate(dateRanges.end)}`
        // );
        let response;
        if (userrole === 3 && clientValueId) {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurposeCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
          );
          console.log("Response data:", response);
        } else {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurposeCount?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
          );
          console.log("Response data:", response);
        }

        const responseData = await response.json();
        console.log("Top Purpose Response data:", responseData);
        if (responseData && responseData.count && responseData.count.length === 0) {
          setTopPurposesData(null);
          setLoading(false);
        } else {
          const formattedData = responseData.count.map((item) => ({
            id: item.purpose,
            value: item.count,
            label: item.purpose,
          }));
          setTopPurposesData(formattedData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchTopPurposesData();
  }, [dateRanges.start, dateRanges.end, clientValueId]);

  useEffect(() => {
    if (!topPurposesData || loading) return;

    const dom = chartRef.current;
    if (!dom) return;

    const myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false,
    });

    const option = {
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        top: "center",
        left: "left",
      },
      series: [
        {
          //name: "Access From",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          padAngle: 5,
          itemStyle: {
            borderRadius: 12,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          labelLine: {
            show: false,
          },
          data: topPurposesData.sort((a, b) => a.value - b.value),
        },
      ],
    };

    if (option && typeof option === "object") {
      myChart.setOption(option);
    }

    window.addEventListener("resize", myChart.resize);

    return () => {
      window.removeEventListener("resize", myChart.resize);
      myChart.dispose();
    };
  }, [topPurposesData, loading]);

  //Navigation
  const handleNavigate = (filtertype) => {
    if (filtertype)
      window.location.href =
        "/microtickets?filtertype=" +
        encodeURIComponent(filtertype) +
        "&start=" +
        encodeURIComponent(dateRanges.start) +
        "&end=" +
        encodeURIComponent(dateRanges.end);
  };

  const calculateTotalValues = (data) => {
    let totalActivated = 0;
    let totalValidated = 0;
    let totalExpired = 0;

    data.forEach((ticket) => {
      totalActivated += parseInt(ticket.activated);
      totalValidated += parseInt(ticket.validated);
      totalExpired += parseInt(ticket.expired);
    });

    setTotalActivatedTickets(totalActivated);
    setTotalValidatedTickets(totalValidated);
    setTotalExpiredTickets(totalExpired);
  };

  // Prepare data for the chart
  const chartData3 = {
    labels: ticketCounts.map((ticket) => ticket.date), // Assuming ticketCounts is an array of objects with a 'date' property
    datasets: [
      {
        label: "Activated",
        borderColor: "rgb(255, 110, 110)",
        data: ticketCounts.map((ticket) => ticket.activated), // Assuming ticketCounts is an array of objects with an 'activated' property
        fill: false, // Disable fill below the line
      },
      {
        label: "Validated",
        borderColor: "rgb(80, 187, 51)",
        data: ticketCounts.map((ticket) => ticket.validated), // Assuming ticketCounts is an array of objects with a 'validated' property
        fill: false, // Disable fill below the line
      },
      {
        label: "Expired",
        borderColor: "rgb(77, 112, 255)",
        backgroundColor: "rgba(238, 238, 238 )", // Define the fill color with transparency

        data: ticketCounts.map((ticket) => ticket.expired), // Assuming ticketCounts is an array of objects with an 'expired' property
        fill: true, // Disable fill below the line
        borderDash: [6, 6], // Define the dotted line pattern
      },
    ],
  };

  //Sorting

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOrder2, setSortOrder2] = useState("desc");

  const renderSortIcon = () => {
    if (sortOrder === "asc") {
      return <Icon name="arrow-up" />;
    } else if (sortOrder === "desc") {
      return <Icon name="arrow-down" />;
    }
  };

  const renderSortIcon2 = () => {
    if (sortOrder2 === "asc") {
      return <Icon name="arrow-up" />;
    } else if (sortOrder2 === "desc") {
      return <Icon name="arrow-down" />;
    }
  };

  const handleSort = (columnName) => {
    const sortedData = [...ticketCounts].sort((a, b) => {
      if (columnName === "date") {
        const sortOrderToggle = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(sortOrderToggle);
        setSortOrder2("desc");
        if (sortOrderToggle === "asc") {
          return new Date(a[columnName]) - new Date(b[columnName]);
        } else {
          return new Date(b[columnName]) - new Date(a[columnName]);
        }
      } else if (columnName === "total") {
        const sortOrderToggle = sortOrder2 === "asc" ? "desc" : "asc";
        setSortOrder2(sortOrderToggle);
        setSortOrder("desc");
        if (sortOrderToggle === "asc") {
          return parseInt(a[columnName]) - parseInt(b[columnName]);
        } else {
          return parseInt(b[columnName]) - parseInt(a[columnName]);
        }
      } else {
        // For other columns, maintain the existing sorting logic
        return a[columnName] > b[columnName] ? 1 : -1;
      }
    });
    setTicketCounts(sortedData);
    setCurrentPage(1);
  };

  const handleClick = (event, chart) => {
    const points = chart.getElementAtEvent(event);

    if (points.length) {
      const firstPoint = points[0];
      const datasetIndex = firstPoint._datasetIndex;
      const label = chart.data.datasets[datasetIndex].label;
      const value = chart.data.datasets[datasetIndex].data[firstPoint._index];
      const date = chart.data.labels[firstPoint._index];

      if (label === "Returning Users" && value != 0) {
        redirectToUsers(date, value, "returnusers");
      } else if (label === "New Users" && value != 0) {
        redirectToUsers(date, value, "newusers");
      }
    }
  };

  useEffect(() => {
    const fetchFareTypes = async () => {
      try {
        let response;

        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}getFarebyAgency?agencyid=${clientValueId}`
          );
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getFarebyAgency?agencyid=${client_id}`);
        }

        if (!response || !response.data || !Array.isArray(response.data)) {
          console.error("Invalid response data:", response);
          return;
        }

        const extractedFareNames = response.data.map((item) => item.farename);
        setFareNamesNew(extractedFareNames);
        setTotalFares(response.data.length);
        setFareNames(response.data);
        setSelectedFare(response.data.length > 0 ? response.data[0].farename : null);
        console.log("Fare names", response.data);
      } catch (error) {
        console.error("Error fetching fare names:", error);
      }
    };

    fetchFareTypes();
  }, [clientValueId]);

  // useEffect(() => {
  //   const fetchChartData = async () => {
  //     try {
  //       let response;
  //       if (userrole === 3 && clientValueId) {
  //         response = await axios.get(
  //           `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseFareCount?startDate=${formatDate(
  //             dateRanges.start
  //           )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
  //         );
  //       } else {
  //         response = await axios.get(
  //           `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseFareCount?startDate=${formatDate(
  //             dateRanges.start
  //           )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
  //         );
  //       }

  //       const responseData = response.data;

  //       if (!responseData || !responseData.data || !Array.isArray(responseData.data)) {
  //         console.error("Invalid response data:", responseData);
  //         return;
  //       }

  //       const dates = responseData.data.map((entry) => entry.date);
  //       setTableDates(dates);

  //       const fareCounts = [];
  //       responseData.data.forEach((item) => {
  //         item.fare.forEach((fareItem) => {
  //           if (fareItem.fareName === selectedFare) {
  //             fareCounts.push(fareItem.count);
  //           }
  //         });
  //       });

  //       if (fareCounts.length === 0) {
  //         console.error(`Selected fare '${selectedFare}' not found in response data.`);
  //         return;
  //       }

  //       setFareData({
  //         labels: dates,
  //         datasets: [
  //           {
  //             label: "Totalsss",
  //             data: fareCounts,
  //             backgroundColor: "#8D47D6",
  //             borderColor: "#8D47D6",
  //             borderWidth: 2,
  //             fill: false,
  //             pointRadius: 1.5,
  //           },
  //         ],
  //       });

  //       const processedData = responseData.data.map((entry) => {
  //         const date = entry.date;
  //         const counts = fareNamesNew.map((fareName) => {
  //           const fare = entry.fare.find((item) => item.fareName === fareName);
  //           return fare ? parseInt(fare.count) : 0;
  //         });
  //         return [date, ...counts];
  //       });

  //       setFareCounts(processedData);
  //       console.log("ProcessedData: ", processedData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchChartData();
  // }, [clientValueId, dateRanges.start, dateRanges.end, selectedFare]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseUserRegistation?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}`
        );
        const responseData = await response.json();

        if (responseData.count.android !== 0 || responseData.count.ios !== 0) {
          const formattedData = [
            {
              id: "Android",
              value: responseData.count.android,
              label: "iOS",
            },
            {
              id: "iOS",
              value: responseData.count.ios,
              label: "iOS",
            },
          ];
          setAndroidIOSData(formattedData);
        } else {
          setAndroidIOSData(null);
        }

        console.log("Response data:", responseData); // Log the responseData to inspect its structure
        if (responseData && responseData.data && responseData.data.length === 0) {
          setTotalCount(0);
          setCount(0);
          setDates([]);
          setNumberOfDates(0);
          setAndroidIOSData(null);
        } else {
          const totalCount = responseData.data.reduce((acc, entry) => acc + parseInt(entry.userCount), 0);
          const count = responseData.data.length;
          const dates = responseData.data.map((entry) => entry.date);
          const numberOfDates = dates.length; // Calculate the number of dates
          const totals = responseData.data.map((entry) => parseInt(entry.userCount));
          setTicketCounts3(responseData.data);
          setLoading2(false); // Update loading state once data is fetched
          // setTotalCount(totalCount);
          setCount(count);
          setDates(dates);
          setNumberOfDates(numberOfDates); // Set the number of dates
          setData({
            labels: dates,
            datasets: [
              {
                label: "Total",
                data: totals,
                backgroundColor: "#8D47D6",
                borderColor: "#8D47D6",
                borderWidth: 2,
                fill: false,

                pointRadius: 1.5,
              },
            ],
          });
        }
      } catch (error) {
        setTotalCount(0);
        setAndroidIOSData(null);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [state, dateRanges.start, dateRanges.end]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseUserRegistation?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}`
        );
        const responseData = await response.json();
        console.log("Response data:", responseData); // Log the responseData to inspect its structure
        if (responseData && responseData.data && responseData.data.length === 0) {
          setData2(null);
          setTotalCount(0);
          setCount(0);
          setDates([]);
          setNumberOfDates(0);
          setLoading(false);
        } else {
          const totalCount = responseData.data.reduce((acc, entry) => acc + parseInt(entry.userCount), 0);
          const count = responseData.data.length;
          const dates = responseData.data.map((entry) => entry.date);
          const numberOfDates = dates.length; // Calculate the number of dates
          const totals = responseData.data.map((entry) => parseInt(entry.userCount));
          setTicketCounts3(responseData.data);
          // setLoading2(false);
          setLoading(false);
          setTotalCount(totalCount);
          setCount(count);
          setDates(dates);
          setNumberOfDates(numberOfDates); // Set the number of dates
          setData2({
            labels: dates,
            datasets: [
              {
                label: "Total",
                data: totals,
                backgroundColor: "rgba(157, 114, 255, 0.15)",
                borderColor: "#003C43",
                borderWidth: 2,
                fill: true,
                pointBorderWidth: 2,
                pointBackgroundColor: "white",
                pointRadius: 4,
              },
            ],
          });
        }
      } catch (error) {
        setLoading(false);
        setData2(null);
        setTotalCount(0);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [state, dateRanges.start, dateRanges.end]);

  // useEffect(() => {
  //   const fetchTopPurposesData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywisePurposeCount?startDate=${formatDate(
  //           dateRanges.start
  //         )}&endDate=${formatDate(dateRanges.end)}`
  //       );
  //       const responseData = await response.json();
  //       console.log("Top Purpose Response data:", responseData);
  //       if (responseData && responseData.data && responseData.data.length === 0) {
  //         setTopPurposesData([]);
  //         setLoading(false);
  //       } else {
  //         setTopPurposesData(responseData);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchTopPurposesData();
  // }, [state, dateRanges.start, dateRanges.end]);

  useEffect(() => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const formattedDate = `${date.toLocaleString("en-us", {
        month: "long",
      })} ${date.getDate()}, ${date.getFullYear()}`;

      dates.push(formattedDate);
    }
    console.log(dates);
    setDefaultDates(dates);
  }, []);
  const [chartData2, setChartData2] = useState(null);
  const [totalCount3, setTotalCount3] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      //Reset sort icons
      setSortOrder("desc");
      setSortOrder2("desc");
      try {
        console.log("Fetching data for date range:", dateRanges.start, "-", dateRanges.end);
        let response;
        if (userrole === 3 && clientValueId) {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetReccuringandNewusers?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
          );
          console.log("Response data:", response);
        } else {
          response = await fetch(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetReccuringandNewusers?startDate=${formatDate(
              dateRanges.start
            )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
          );
          console.log("Response data:", response);
        }

        const data = await response.json();
        const dates = data.data.map((entry) => new Date(entry.date));
        const userCounts = data.data.map((entry) => parseInt(entry.totalUsers));

        setChartData2({
          labels: dates,
          datasets: [
            {
              label: "Total",
              data: userCounts,
              backgroundColor: "rgba(157, 114, 255, 0.15)",
              borderColor: "#D83A56",
              borderWidth: 2,
              fill: true,
              pointBorderWidth: 2,
              pointBackgroundColor: "white",
              pointRadius: 4,
            },
          ],
        });

        // Calculate total users count
        const totalUsersCount = userCounts.reduce((total, count) => total + count, 0);
        setTotalCount3(totalUsersCount); // Set the total users count state
      } catch (error) {
        setChartData2(null);
        setTotalCount3(0);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [clientValueId, dateRanges.start, dateRanges.end]);

  //Set client name based on the role
  useEffect(() => {
    if (userrole === 3) {
      setClientname(clientValueLabel);
    } else {
      setClientname(user);
    }
  }, [clientValueLabel]);

  const [usersChartData, setUsersChartData] = useState(null);

  const [newUsers, setNewUsers] = useState(0);
  const [existingUsers, setExistingUsers] = useState(0);

  const fetchUsersData = async () => {
    try {
      let response;
      if (userrole === 3 && clientValueId) {
        response = await axios.get(
          `${process.env.REACT_APP_CONFIG_BASE_URL}v2/GetReccuringandNewusers?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${clientValueId}`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_CONFIG_BASE_URL}v2/GetReccuringandNewusers?startDate=${formatDate(
            dateRanges.start
          )}&endDate=${formatDate(dateRanges.end)}&client_id=${client_id}`
        );
      }

      const apiData = response.data;
      console.log("New vs Return Chart Response", apiData);

      const dates = apiData.data.map((entry) => entry.date);
      const existingUsers = apiData.data.map((entry) => entry.existingUsers);
      const newUsers = apiData.data.map((entry) => entry.newUsers);

      let totalExistingUsers = 0;
      let totalNewUsers = 0;

      apiData.data.forEach((entry) => {
        totalExistingUsers += entry.existingUsers;
        totalNewUsers += entry.newUsers;
      });

      setExistingUsers(totalExistingUsers);
      setNewUsers(totalNewUsers);

      setUsersChartData({
        labels: dates,
        dataUnit: "People",
        datasets: [
          {
            label: "Returning Users",
            borderDash: [5],
            borderWidth: 2,
            fill: false,
            borderColor: "#D20062",
            backgroundColor: "transparent",
            pointBorderColor: "#D20062",
            pointBackgroundColor: "#D20062",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#9d72ff",
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: existingUsers,
          },
          {
            label: "New Users",
            color: "#9d72ff",
            borderWidth: 2,
            lineTension: 0,
            dash: 0,
            borderColor: "#F57D1F",
            backgroundColor: "rgba(157, 114, 255, 0.15)",
            borderCapStyle: "square",
            pointBorderColor: "#F57D1F",
            pointBackgroundColor: "#F57D1F",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#9d72ff",
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: newUsers,
          },
        ],
      });
    } catch (error) {
      setUsersChartData(null);
      setNewUsers(0);
      setExistingUsers(0);
      console.error("Error fetching data:", error);
    }
  };

  //Export charts as PNG and PDF

  const exportNoDataAlert = () => {
    toast.error("There is no data available for export!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  const exportSuccessMsg = () => {
    toast.dark("Chart Report Exported Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      icon: false,
    });
  };

  const exportAllSuccessMsg = () => {
    toast.success("All Reports Exported Successfully", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };
  // const captureChart = (chartid, downloadid, filename) => {
  //   const chartContainer = document.getElementById(chartid);
  //   const elementToExclude = document.getElementById(downloadid);

  //   html2canvas(chartContainer, {
  //     ignoreElements: (element) => {
  //       return element === elementToExclude;
  //     },
  //   }).then((canvas) => {
  //     const link = document.createElement("a");
  //     link.download = `${filename}.png`;
  //     link.href = canvas.toDataURL();
  //     link.click();

  //     exportSuccessMsg();
  //   });
  // };

  //Branding logo
  const captureChart = async (chartid, downloadid, filename, chartInfo) => {
    if (Array.isArray(chartInfo)) {
      if (chartInfo.length === 0) {
        exportNoDataAlert();
        return;
      }
    } else if (!chartInfo) {
      exportNoDataAlert();
      return;
    }

    const chartContainer = document.getElementById(chartid);
    const elementToExclude = document.getElementById(downloadid);

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    const logoWidth = 120;
    const logoHeight = 120;

    logo.onload = async () => {
      try {
        const chartCanvas = await html2canvas(chartContainer, {
          ignoreElements: (element) => element === elementToExclude,
        });

        const combinedCanvas = document.createElement("canvas");
        const context = combinedCanvas.getContext("2d");

        combinedCanvas.width = chartCanvas.width;
        combinedCanvas.height = logoHeight + chartCanvas.height;

        context.fillStyle = "#ffffff";
        context.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);

        const logoX = combinedCanvas.width - logoWidth;
        const logoY = 0;

        context.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

        context.font = "bold 35px Times New Roman";
        context.fillStyle = "red";
        const labelX = 30;
        const labelY = 70;
        context.fillText(clientname, labelX, labelY);

        context.drawImage(chartCanvas, 0, logoHeight);

        const link = document.createElement("a");
        link.download = `${filename}.png`;
        link.href = combinedCanvas.toDataURL();
        link.click();

        exportSuccessMsg();
      } catch (error) {
        console.error("Error capturing chart:", error);
      }
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");
    };
  };

  // const captureChartPDF = (chartid, downloadid, filename) => {
  //   const chartContainer = document.getElementById(chartid);
  //   const elementToExclude = document.getElementById(downloadid);

  //   if (!elementToExclude) {
  //     console.error("Element with id 'downloaddata' not found.");
  //     return;
  //   }

  //   html2canvas(chartContainer, {
  //     ignoreElements: (element) => {
  //       return element === elementToExclude;
  //     },
  //   })
  //     .then((canvas) => {
  //       const imageData = canvas.toDataURL("image/jpeg");

  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const imgWidth = 190; // Width of the PDF
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       pdf.addImage(imageData, "JPEG", 10, 10, imgWidth, imgHeight);
  //       pdf.save(`${filename}.pdf`);
  //       exportSuccessMsg();
  //     })
  //     .catch((error) => {
  //       console.error("Error capturing chart:", error);
  //     });
  // };

  const captureChartPDF = (chartid, downloadid, filename, chartInfo) => {
    if (Array.isArray(chartInfo)) {
      if (chartInfo.length === 0) {
        exportNoDataAlert();
        return;
      }
    } else if (!chartInfo) {
      exportNoDataAlert();
      return;
    }

    const chartContainer = document.getElementById(chartid);
    const elementToExclude = document.getElementById(downloadid);

    if (!elementToExclude) {
      console.error("Element with id 'downloaddata' not found.");
      return;
    }

    const pdf = new jsPDF("p", "mm", "a4");

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    logo.onload = () => {
      const imgWidth = 30;
      const imgHeight = 30;
      const logoX = pdf.internal.pageSize.width - imgWidth - 10;
      const logoY = 2;
      pdf.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

      pdf.setFont("times", "bold");
      pdf.setFontSize(20);
      pdf.setDrawColor(0);
      pdf.setTextColor(255, 0, 0);
      pdf.text(clientname, 10, 20);

      html2canvas(chartContainer, {
        ignoreElements: (element) => element === elementToExclude,
      })
        .then((chartCanvas) => {
          const chartImageData = chartCanvas.toDataURL("image/jpeg");

          const chartImgWidth = 190;
          const chartImgHeight = 100;
          const chartX = 10;
          const chartY = 30;
          pdf.addImage(chartImageData, "JPEG", chartX, chartY, chartImgWidth, chartImgHeight);

          pdf.save(`${filename}.pdf`);
          exportSuccessMsg();
        })
        .catch((error) => {
          console.error("Error capturing chart:", error);
        });
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");
    };
  };

  // const captureAllPDF = (chartid, downloadid, filename) => {
  //   const chartContainer = document.getElementById(chartid);
  //   const elementToExclude = document.getElementById(downloadid);

  //   if (!elementToExclude) {
  //     console.error("Element with id 'downloaddata' not found.");
  //     return;
  //   }

  //   html2canvas(chartContainer, {
  //     ignoreElements: (element) => {
  //       return element === elementToExclude;
  //     },
  //   })
  //     .then((canvas) => {
  //       const imageData = canvas.toDataURL("image/jpeg");

  //       const imgWidth = 180; // Width of the PDF
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       // const pdf = new jsPDF('p', 'mm', 'a4');
  //       const pdf = new jsPDF({
  //         unit: "mm",
  //         format: [imgWidth + 20, imgHeight + 20],
  //       });

  //       pdf.addImage(imageData, "JPEG", 10, 10, imgWidth, imgHeight);
  //       pdf.save(`${filename}.pdf`);
  //       exportAllSuccessMsg();
  //     })
  //     .catch((error) => {
  //       console.error("Error capturing chart:", error);
  //     });
  // };

  // const captureAllPDF = (chartid, downloadid, filename) => {
  //   if (!data2 && !chartData2 && !usersChartData && ticketCounts.length === 0) {
  //     exportNoDataAlert();
  //     return;
  //   }
  //   const chartContainer = document.getElementById(chartid);
  //   const elementToExclude = document.getElementById(downloadid);

  //   if (!elementToExclude) {
  //     console.error("Element with id 'downloaddata' not found.");
  //     return;
  //   }

  //   html2canvas(chartContainer, {
  //     ignoreElements: (element) => element === elementToExclude,
  //   })
  //     .then((canvas) => {
  //       const imageData = canvas.toDataURL("image/jpeg");

  //       const imgWidth = 180;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //       const pdf = new jsPDF({
  //         unit: "mm",
  //         format: "a4",
  //       });

  //       const logo = new Image();
  //       logo.crossOrigin = "anonymous";
  //       logo.src = logoUrl;

  //       logo.onload = () => {
  //         const logoWidth = 30;
  //         const logoHeight = 30;
  //         const logoX = pdf.internal.pageSize.width - logoWidth - 10;
  //         const logoY = 2;
  //         pdf.addImage(logo, "JPEG", logoX, logoY, logoWidth, logoHeight);

  //         pdf.setFont("times", "bold");
  //         pdf.setFontSize(20);
  //         pdf.setDrawColor(0);
  //         pdf.setTextColor(255, 0, 0);
  //         pdf.text(clientname, 10, 20);

  //         pdf.addImage(imageData, "JPEG", 10, 30, imgWidth, imgHeight);

  //         pdf.save(`${filename}.pdf`);
  //         exportAllSuccessMsg();
  //       };

  //       logo.onerror = () => {
  //         console.error("Failed to load logo image");
  //       };
  //     })
  //     .catch((error) => {
  //       console.error("Error capturing chart:", error);
  //     });
  // };

  //Two pages
  const captureAllPDF = (chartid1, chartid2, downloadid, filename) => {
    if (!data2 && !chartData2 && !usersChartData && ticketCounts.length === 0) {
      exportNoDataAlert();
      return;
    }

    // Function to capture chart and return a promise with the canvas and image data
    const captureChart = (chartid) => {
      const chartContainer = document.getElementById(chartid);
      const elementToExclude = document.getElementById(downloadid);

      if (!elementToExclude) {
        console.error(`Element with id '${downloadid}' not found.`);
        return Promise.reject(new Error("Element to exclude not found"));
      }

      return html2canvas(chartContainer, {
        ignoreElements: (element) => element === elementToExclude,
      })
        .then((canvas) => {
          return {
            imageData: canvas.toDataURL("image/jpeg"),
            width: canvas.width,
            height: canvas.height,
          };
        })
        .catch((error) => {
          console.error(`Error capturing chart ${chartid}:`, error);
          return Promise.reject(error);
        });
    };

    // Capture both charts and create PDF
    Promise.all([captureChart(chartid1), captureChart(chartid2)])
      .then(([chart1, chart2]) => {
        const imgWidth = 180;
        const imgHeight1 = (chart1.height * imgWidth) / chart1.width;
        const imgHeight2 = (chart2.height * imgWidth) / chart2.width;

        const pdf = new jsPDF({
          unit: "mm",
          format: "a4",
        });

        const logo = new Image();
        logo.crossOrigin = "anonymous";
        logo.src = logoUrl;

        logo.onload = () => {
          const logoWidth = 30;
          const logoHeight = 30;

          // Page 1
          pdf.addImage(logo, "JPEG", pdf.internal.pageSize.width - logoWidth - 10, 2, logoWidth, logoHeight);
          pdf.setFont("times", "bold");
          pdf.setFontSize(20);
          pdf.setDrawColor(0);
          pdf.setTextColor(255, 0, 0);
          pdf.text(clientname, 10, 20);
          pdf.addImage(chart1.imageData, "JPEG", 10, 30, imgWidth, imgHeight1);
          pdf.addPage();

          // Page 2
          pdf.addImage(logo, "JPEG", pdf.internal.pageSize.width - logoWidth - 10, 2, logoWidth, logoHeight);
          pdf.setFont("times", "bold");
          pdf.setFontSize(20);
          pdf.setDrawColor(0);
          pdf.setTextColor(255, 0, 0);
          pdf.text(clientname, 10, 20);
          pdf.addImage(chart2.imageData, "JPEG", 10, 30, imgWidth, imgHeight2);

          pdf.save(`${filename}.pdf`);
          exportAllSuccessMsg();
        };

        logo.onerror = () => {
          console.error("Failed to load logo image");
        };
      })
      .catch((error) => {
        console.error("Error capturing charts:", error);
      });
  };

  //Export table as CSV and PDF

  const exportExcel = () => {
    if (ticketCounts.length === 0) {
      exportNoDataAlert();
      return;
    }
    const fileName = `Ticket Status [${formatDate(dateRanges.start)} - ${formatDate(dateRanges.end)}]`;
    const exportedData = ticketCounts.map((row) => ({
      Date: row.date,
      Activated: row.activated,
      Unactivated: row.unactivated,
      Validated: row.validated,
      Unvalidated: row.unvalidated,
      Expired: row.expired,
      Total: row.total,
    }));
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data: exportedData, fileName, exportType });
    exportSuccessMsg();
  };

  // const exportPDF = () => {
  //   console.log("Export data:", ticketCounts);
  //   const fileName = `Ticket Status [${formatDate(dateRanges.start)} - ${formatDate(dateRanges.end)}]`;
  //   const doc = new jsPDF("landscape");
  //   doc.text("Ticket Status", 10, 10);

  //   const tableData = ticketCounts.map((row) =>
  //     exportColumns.map((col) => {
  //       const selectorResult = col.selector(row);
  //       if (typeof col.selector === "function") {
  //         return selectorResult;
  //       } else {
  //         return row[col.selector];
  //       }
  //     })
  //   );

  //   doc.autoTable({
  //     head: [exportColumns.map((col) => col.name)],
  //     body: tableData,
  //   });

  //   doc.output("dataurlnewwindow", { filename: fileName });
  //   doc.save(fileName);
  // };

  useEffect(() => {
    fetchUsersData();
  }, [clientValueId, dateRanges.start, dateRanges.end, selectedFare]);

  const fetchCO2Emissions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CO2_URL}GetCO2EmissionNew?startDate=${formatDate(
          dateRanges.start
        )}&endDate=${formatDate(dateRanges.end)}`
      );
      processResponse(response.data);
    } catch (error) {
      console.error("Error fetching CO2 emissions:", error);
    }
  };

  const [totalCO2Count, setTotalCO2Count] = useState(0);
  const [dataCO22, setCO2Data2] = useState(0);

  const processResponse = (data) => {
    const labels = data.map((item) => item.Date.split("T")[0]); // Extract dates
    const values = data.map((item) => item.Total_CO2); // Extract CO2 values

    setCO2Data2({
      labels: labels,
      datasets: [
        {
          label: "CO2 Emissions",
          data: values,
          fill: false,
          borderColor: "#D83A56",
          tension: 0.1,
        },
      ],
    });

    // Calculate total CO2 emissions reduced
    const totalEmissionsReduced = values.reduce((acc, val) => acc + val, 0);
    setTotalCO2Count(totalEmissionsReduced);
  };

  // Step 3: Fetch data when component mounts
  useEffect(() => {
    fetchCO2Emissions();
  }, []);

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                {userrole === 3 && clientValueLabel ? (
                  // clientValueLabel ? (
                  //   <BlockTitle page tag="h3">
                  //     Dashboard <span style={{ color: "#0079C2" }}>{clientValueLabel}</span>
                  //   </BlockTitle>
                  // ) : (
                  //   <BlockTitle page tag="h3">
                  //     Please select Client
                  //   </BlockTitle>
                  // )
                  <div className="d-flex align-items-start justify-content-start">
                    <BlockTitle page tag="h3">
                      Dashboard
                    </BlockTitle>
                    <UncontrolledDropdown className="ml-2">
                      <DropdownToggle tag="div" color="transparent">
                        <a
                          style={{ fontSize: "18px" }}
                          href="#toggle"
                          onClick={(ev) => ev.preventDefault()}
                          className="btn btn-secondary btn-dim d-none d-sm-inline-flex"
                        >
                          <Icon className="d-none d-sm-inline mr-1" name="user-circle-fill" />
                          {clientValueLabel}
                        </a>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </div>
                ) : (
                  <BlockTitle page tag="h3">
                    Dashboard
                  </BlockTitle>
                )}
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </div>
        {isLoading ? (
          <div className="text-center pt-3 pb-3">
            <Spinner color="primary" />
          </div>
        ) : (
          <Block>
            <div id="allreports1">
              <Row className="g-gs">
                <Col md="12">
                  <div style={{ marginTop: 10 }}>
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="d-flex align-items-end" style={{ marginTop: "-5px" }}>
                        <div style={{ textAlign: "right" }}>
                          <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                            <DropdownToggle color="primary" className="dropdown-toggle">
                              <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                              {selectedOption} {/* Display the selected option */}
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu">
                              <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>
                                Last 3 Months
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="ml-2">
                          <BlockTitle page tag="h3">
                            <DatePicker
                              selected={dateRanges.start}
                              startDate={dateRanges.start}
                              onChange={onRangeChange}
                              endDate={dateRanges.end}
                              selectsRange
                              className="form-control date-picker"
                            />
                          </BlockTitle>
                        </div>
                      </div>
                      <div className="excel" id="downloadall">
                        <Button
                          color="danger"
                          onClick={() => {
                            captureAllPDF(
                              "allreports1",
                              "allreports2",
                              "downloadall",
                              `Dashboard [${formatDate(dateRanges.start)} - ${formatDate(dateRanges.end)}]`
                            );
                            // successAlert("Exported as Excel");
                          }}
                        >
                          <Icon className="d-none d-sm-inline mr-1" name="download-cloud" />
                          Download All
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row className="g-gs">
                  <Col xxl="4" md="6 ">
                    <Card>
                      <div className="nk-ecwg nk-ecwg2">
                        <div className="card-inner">
                          <React.Fragment>
                            <div
                              className="card-title-group mb-3"
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <div className="card-title">
                                <h6 className="title">Registered Users</h6>
                                <p>Number of users registered in a day</p>

                              </div>
                              <div className="total-count" style={{ textAlign: "right" }}>
                                <div className="title">Total Registered Users</div>
                                <div className="amount">
                                  <h2 style={{ textAlign: "center", color: "#8D47D6" }}>
                                    <CountUp className="rolling-number" duration={2} end={totalCount} />
                                  </h2>
                                </div>
                              </div>
                            </div>

                            <ul className="nk-ecwg8-legends"></ul>
                            <div className="nk-ecwg8-ck">
                              {!isLoading && ticketCounts && ticketCounts.length > 0 && (
                                <Line
                                  className="ecommerce-line-s4"
                                  data={data}
                                  options={{
                                    legend: {
                                      display: false,
                                    },
                                    maintainAspectRatio: false,
                                    tooltips: {
                                      enabled: true,
                                      callbacks: {
                                        title: function (tooltipItems, data) {
                                          return data.labels[tooltipItems[0].index];
                                        },
                                        label: function (tooltipItem, data) {
                                          return `Count: ${tooltipItem.yLabel}`;
                                        },
                                      },
                                      backgroundColor: "#1c2b46",
                                      titleFontSize: 13,
                                      titleFontColor: "#fff",
                                      titleMarginBottom: 6,
                                      bodyFontColor: "#fff",
                                      bodyFontSize: 12,
                                      bodySpacing: 4,
                                      yPadding: 10,
                                      xPadding: 10,
                                      footerMarginTop: 0,
                                      displayColors: false,
                                    },
                                    scales: {
                                      yAxes: [
                                        {
                                          display: true,
                                          scaleLabel: {
                                            display: true,
                                            labelString: "Count \u2192", // Title for the y-axis with up arrow
                                            fontSize: 12,
                                            fontColor: "#9eaecf",
                                          },
                                          ticks: {
                                            beginAtZero: true,
                                            fontSize: 11,
                                            fontColor: "#9eaecf",
                                            padding: 10,
                                            callback: function (value, index, values) {
                                              return value;
                                            },
                                            min: 0,
                                            stepSize: 3000,
                                          },
                                          gridLines: {
                                            color: "rgba(82, 100, 132, 0.2)",
                                            tickMarkLength: 0,
                                            zeroLineColor: "rgba(82, 100, 132, 0.2)",
                                          },
                                        },
                                      ],
                                      xAxes: [
                                        {
                                          display: true,
                                          scaleLabel: {
                                            display: true,
                                            labelString: "Date \u2192", // Title for the x-axis with right arrow
                                            fontSize: 12,
                                            fontColor: "#9eaecf",
                                          },
                                          ticks: {
                                            fontSize: 9,
                                            fontColor: "#9eaecf",
                                            padding: 10,
                                            callback: function (value, index, values) {
                                              return data.labels[index]; // Display the date labels
                                            },
                                          },
                                          gridLines: {
                                            color: "transparent",
                                            tickMarkLength: 0,
                                            zeroLineColor: "transparent",
                                          },
                                        },
                                      ],
                                    },
                                    elements: {

                                      line: {
                                        tension: 0, // Set line tension to 0 for straight lines
                                      },
                                    },
                                  }}
                                />
                              )}
                              {!ticketCounts && <div className="no-data">No data found</div>}
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xxl="4" md="6 ">
                    <Card>
                      <div className="nk-ecwg nk-ecwg2">
                        <div className="card-inner">
                          <React.Fragment>
                            <div
                              className="card-title-group mb-3"
                              style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <div className="card-title">
                                <h6 className="title">Purchased Users</h6>
                                <p>Number of users purchased a ticket in a day</p>
                              </div>
                              <div className="total-count" style={{ textAlign: "right" }}>
                                <div className="title">Total Purchased Users</div>
                                <div className="amount">
                                  <h2 style={{ textAlign: "center", color: "#8D47D6" }}>
                                    <CountUp className="rolling-number" duration={2} end={totalCount3} />
                                  </h2>
                                </div>
                              </div>
                            </div>

                            <ul className="nk-ecwg8-legends"></ul>
                            <div className="nk-ecwg8-ck">
                              <Bar
                                data={chartData2}
                                options={{
                                  legend: {
                                    display: false,
                                  },
                                  maintainAspectRatio: false,
                                  scales: {
                                    yAxes: [
                                      {
                                        ticks: {
                                          beginAtZero: true,
                                          stepSize: 1,
                                        },
                                        
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        type: 'time',
                                        time: {
                                          unit: 'day', // Display by day
                                          displayFormats: {
                                            day: 'MMM DD', // Format for displaying dates (e.g., Jan 01)
                                          },
                                        },
                                        gridLines: {
                                          display: false,
                                        },
                                        ticks: {
                                          min: chartData2.labels && chartData2.labels.length > 0 ? chartData2.labels[0] : null, // Check if chartData2.labels is defined and has length
                                          padding: 6,

                                        },
                                      },
                                    ],
                                  },
                                }}
                              />

                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row> */}
              {/* <Row>
              <Col md="6">
                <PreviewAltCard>
                  <ActiveUser />
                </PreviewAltCard>
              </Col>
            </Row> */}
              <Row className="g-gs">
                <Col xxl="6" md="6">
                  <Card id="registeredusers">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <div className="d-flex align-items-start">
                                <h6 className="title">Registered Users</h6>
                                <Icon name="chevrons-right ml-1" style={{ fontSize: "20px" }}></Icon>
                              </div>
                              <p>Number of users registered in a day</p>
                            </div>

                            <div className="d-flex flex-row align-items-start Justify-content-end">
                              <div className="total-count">
                                <div className="title">Total Registered Users</div>
                                <div className="amount center">
                                  <h2
                                    style={{ color: "#135D66", cursor: "pointer" }}
                                    onClick={
                                      totalCount !== 0
                                        ? () => redirectToTotalUsers(dateRanges.start, dateRanges.end, "reguserstot")
                                        : null
                                    }
                                  >
                                    <span id={totalCount !== 0 ? "totalregtooltip" : ""}>
                                      <CountUp className="rolling-number" duration={2} end={totalCount} />
                                    </span>
                                  </h2>
                                </div>
                                {totalCount !== 0 && (
                                  <UncontrolledPopover placement="right" target="totalregtooltip" trigger="hover">
                                    <PopoverBody>View All</PopoverBody>
                                  </UncontrolledPopover>
                                )}
                              </div>

                              <div className="card-tools ml-2" id="download1">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "registeredusers",
                                              "download1",
                                              `Registered Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              data2
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "registeredusers",
                                              "download1",
                                              `Registered Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              data2
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>

                          <ul className="nk-ecwg8-legends"></ul>
                          <div className="nk-ecwg8-ck">
                            {data2 ? (
                              <Line
                                className="line-chart"
                                data={data2}
                                options={{
                                  legend: {
                                    display: false,
                                  },
                                  maintainAspectRatio: false,
                                  tooltips: {
                                    enabled: true,
                                    callbacks: {
                                      title: function (tooltipItems, data) {
                                        return data.labels[tooltipItems[0].index];
                                      },
                                      label: function (tooltipItem, data) {
                                        return `Count: ${tooltipItem.yLabel}`;
                                      },
                                    },
                                    backgroundColor: "#1c2b46",
                                    titleFontSize: 13,
                                    titleFontColor: "#fff",
                                    titleMarginBottom: 6,
                                    bodyFontColor: "#fff",
                                    bodyFontSize: 12,
                                    bodySpacing: 4,
                                    yPadding: 10,
                                    xPadding: 10,
                                    footerMarginTop: 0,
                                    displayColors: false,
                                  },
                                  scales: {
                                    yAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Count \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        ticks: {
                                          beginAtZero: true,
                                          fontSize: 11,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                          callback: function (value, index, values) {
                                            return value;
                                          },
                                          min: 0,
                                          stepSize: 3000,
                                        },
                                        gridLines: {
                                          color: "rgba(82, 100, 132, 0.2)",
                                          tickMarkLength: 0,
                                          zeroLineColor: "rgba(82, 100, 132, 0.2)",
                                        },
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Date \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        ticks: {
                                          fontSize: 9,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                          callback: function (value, index, values) {
                                            if (
                                              data &&
                                              data.labels &&
                                              Array.isArray(data.labels) &&
                                              index < data.labels.length
                                            ) {
                                              return data.labels[index];
                                            } else {
                                              return "";
                                            }
                                          },
                                        },
                                        gridLines: {
                                          color: "transparent",
                                          tickMarkLength: 0,
                                          zeroLineColor: "transparent",
                                        },
                                      },
                                    ],
                                  },
                                  elements: {
                                    line: {
                                      tension: 0,
                                    },
                                  },
                                  onClick: function (evt, activeElements) {
                                    if (activeElements.length > 0) {
                                      const index = activeElements[0]._index;
                                      const date = data2.labels[index];
                                      const value = data2.datasets[0].data[index];
                                      if (value != 0) {
                                        redirectToUsers(date, value, "regusers"); //Client id no need, just passing for reference
                                      }
                                    }
                                  },
                                }}
                              />
                            ) : (
                              <div className="text-center mt-2">No Users Registered </div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col xxl="6" md="6 ">
                  <Card id="purchasedusers">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <div className="d-flex align-items-start">
                                <h6 className="title">Purchased Users</h6>
                                <Icon name="chevrons-right ml-1" style={{ fontSize: "20px" }}></Icon>
                              </div>
                              <p>Number of users purchased a ticket in a day</p>
                            </div>

                            <div className="d-flex flex-row align-items-start justify-content-end">
                              <div className="total-count" style={{ textAlign: "right" }}>
                                <div className="title">Total Purchased Users</div>
                                <div className="amount">
                                  <h2
                                    style={{ textAlign: "center", color: "#D83A56", cursor: "pointer" }}
                                    onClick={
                                      purchaseTot !== 0
                                        ? () =>
                                            redirectToTotalUsers(dateRanges.start, dateRanges.end, "purchaseduserstot")
                                        : null
                                    }
                                  >
                                    <span id={purchaseTot !== 0 ? "totalpurctooltip" : ""}>
                                      <CountUp className="rolling-number" duration={2} end={purchaseTot} />
                                    </span>
                                  </h2>
                                  {purchaseTot !== 0 && (
                                    <UncontrolledPopover placement="right" target="totalpurctooltip" trigger="hover">
                                      <PopoverBody>View All</PopoverBody>
                                    </UncontrolledPopover>
                                  )}
                                </div>
                              </div>

                              <div className="card-tools ml-2" id="download2">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "purchasedusers",
                                              "download2",
                                              `Purchased Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              chartData2
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "purchasedusers",
                                              "download2",
                                              `Purchased Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              chartData2
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>

                          <ul className="nk-ecwg8-legends"></ul>
                          <div className="nk-ecwg8-ck">
                            {chartData2 ? (
                              <Line
                                className="line-chart"
                                data={chartData2}
                                options={{
                                  legend: {
                                    display: false,
                                  },
                                  maintainAspectRatio: false,
                                  tooltips: {
                                    enabled: true,

                                    backgroundColor: "#1c2b46",
                                    titleFontSize: 13,
                                    titleFontColor: "#fff",
                                    titleMarginBottom: 6,
                                    bodyFontColor: "#fff",
                                    bodyFontSize: 12,
                                    bodySpacing: 4,
                                    yPadding: 10,
                                    xPadding: 10,
                                    footerMarginTop: 0,
                                    displayColors: false,
                                  },
                                  scales: {
                                    yAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Count \u2192", // Title for the y-axis with up arrow
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        ticks: {
                                          beginAtZero: true,
                                          fontSize: 11,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                          min: 0,
                                          stepSize: 3000,
                                        },
                                        gridLines: {
                                          color: "rgba(82, 100, 132, 0.2)",
                                          tickMarkLength: 0,
                                          zeroLineColor: "rgba(82, 100, 132, 0.2)",
                                        },
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Date \u2192", // Title for the x-axis with right arrow
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        type: "time",
                                        time: {
                                          unit: "day", // Display by day
                                          displayFormats: {
                                            day: "MMM DD", // Format for displaying dates (e.g., Jan 01)
                                          },
                                        },
                                        ticks: {
                                          fontSize: 9,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                        },
                                        gridLines: {
                                          color: "transparent",
                                          tickMarkLength: 0,
                                          zeroLineColor: "transparent",
                                        },
                                      },
                                    ],
                                  },
                                  onClick: function (evt, activeElements) {
                                    if (activeElements.length > 0) {
                                      const index = activeElements[0]._index;
                                      const date = chartData2.labels[index];
                                      const value = chartData2.datasets[0].data[index];
                                      if (value != 0) {
                                        redirectToUsers(date, value, "purchasedusers"); //Client id no need, just passing for reference
                                      }
                                    }
                                  },
                                }}
                              />
                            ) : (
                              <div className="text-center mt-2">No Tickets Purchased by Users</div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* Recurring and new users report */}
              <Row className="g-gs">
                <Col
                  xxl={process.env.REACT_APP_PANEL_TEXT === "MDOT" ? "12" : "6"}
                  md={process.env.REACT_APP_PANEL_TEXT === "MDOT" ? "12" : "6"}
                >
                  <Card className="pr-2 pb-4" id="newvsreturnusers">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <div className="d-flex align-items-start">
                                <h6 className="title">New vs Returning Users</h6>
                                <Icon name="chevrons-right ml-1" style={{ fontSize: "20px" }}></Icon>
                              </div>
                              <p>Number of new and existing users</p>
                            </div>

                            <div className="d-flex flex-row align-items-start justify-content-end">
                              <div className="d-flex flex-row justify-content-end">
                                <div className="d-flex flex-column mr-3">
                                  <div
                                    className="d-flex flex-row align-items-center"
                                    id={newTot !== 0 ? "totalnewtottooltip" : ""}
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                      newTot !== 0
                                        ? () => redirectToTotalUsers(dateRanges.start, dateRanges.end, "newuserstot")
                                        : null
                                    }
                                  >
                                    <Icon style={{ fontSize: 30, color: "#F57D1F" }} name="users"></Icon>
                                    <span style={{ fontSize: 30 }}>{newTot}</span>
                                    {newTot !== 0 && (
                                      <UncontrolledPopover
                                        placement="right"
                                        target="totalnewtottooltip"
                                        trigger="hover"
                                      >
                                        <PopoverBody>View All</PopoverBody>
                                      </UncontrolledPopover>
                                    )}
                                  </div>
                                  <span className="title">New Users</span>
                                </div>
                                <div className="d-flex flex-column">
                                  <div
                                    className="d-flex flex-row align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                      returnTot !== 0
                                        ? () => redirectToTotalUsers(dateRanges.start, dateRanges.end, "returnuserstot")
                                        : null
                                    }
                                  >
                                    <Icon style={{ fontSize: 30, color: "#D20062" }} name="user-list"></Icon>
                                    <span id={returnTot !== 0 ? "totalreturntottooltip" : ""} style={{ fontSize: 30 }}>
                                      {returnTot}
                                    </span>
                                  </div>
                                  <span className="title">Returning Users</span>
                                  {returnTot !== 0 && (
                                    <UncontrolledPopover
                                      placement="right"
                                      target="totalreturntottooltip"
                                      trigger="hover"
                                    >
                                      <PopoverBody>View All</PopoverBody>
                                    </UncontrolledPopover>
                                  )}
                                </div>
                              </div>

                              <div className="card-tools mb-4" id="download3">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "newvsreturnusers",
                                              "download3",
                                              `New vs Returning Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              usersChartData
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "newvsreturnusers",
                                              "download3",
                                              `New vs Returning Users [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              usersChartData
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>
                          <div style={{ height: "200px" }}>
                            {usersChartData ? (
                              <Line
                                className="analytics-line-large"
                                data={usersChartData}
                                options={{
                                  legend: {
                                    display: false,
                                    labels: {
                                      boxWidth: 12,
                                      padding: 20,
                                      fontColor: "#6783b8",
                                    },
                                  },
                                  maintainAspectRatio: false,
                                  tooltips: {
                                    enabled: true,
                                    backgroundColor: "#1c2b46",
                                    titleFontSize: 13,
                                    titleFontColor: "#fff",
                                    titleMarginBottom: 6,
                                    bodyFontColor: "#fff",
                                    bodyFontSize: 12,
                                    bodySpacing: 4,
                                    yPadding: 10,
                                    xPadding: 10,
                                    footerMarginTop: 0,
                                    displayColors: false,
                                  },
                                  scales: {
                                    yAxes: [
                                      {
                                        display: true,
                                        ticks: {
                                          beginAtZero: true,
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                          padding: 8,
                                          stepSize: 100,
                                        },
                                        gridLines: {
                                          color: "rgba(82, 100, 132, 0.2)",
                                          tickMarkLength: 0,
                                          zeroLineColor: "rgba(82, 100, 132,0.2)",
                                        },
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Count \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        display: true,
                                        ticks: {
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        gridLines: {
                                          display: false,
                                        },
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Date \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                      },
                                    ],
                                  },
                                  elements: {
                                    line: {
                                      tension: 0,
                                    },
                                  },
                                  onClick: (event, elements) => handleClick(event, elements[0]._chart),
                                }}
                              ></Line>
                            ) : (
                              <div className="text-center mt-2">No New and Existing Users</div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>
                {/* <Col xxl="6" md="6">
                    <Card className="pr-2 pb-4" id="toppurposes">
                      {!loading && topPurposesData && topPurposesData.length === 0 && (
                        <div className="text-center mt-2">No New and Existing Users</div>
                      )}
                      <div>
                        <div id="chart-container" ref={chartRef} style={{ width: "100%", height: "350px" }}></div>
                      </div>
                    </Card>
                  </Col> */}
                {process.env.REACT_APP_PANEL_TEXT === "MDOT" &&
                  localStorage.getItem("email") !== "marquette@zed.digital" &&
                  localStorage.getItem("email") !== "sjcta@zed.digital" &&
                  localStorage.getItem("email") !== "lakeerietransit@zed.digital" && (
                    <Col xxl="6" md="6">
                      <Card className="pr-2 pb-4" id="trippurpose">
                        <div className="nk-ecwg nk-ecwg2">
                          <div className="card-inner">
                            <React.Fragment>
                              <div
                                className="card-title-group mb-3"
                                style={{ display: "flex", justifyContent: "space-between" }}
                              >
                                <div className="card-title">
                                  <div className="d-flex align-items-start">
                                    <h6 className="title">Trip Purposes Breakdown</h6>
                                    <Icon name="chevrons-right ml-1" style={{ fontSize: "20px" }}></Icon>
                                  </div>
                                  <p>Distribution of Bookings by Purpose</p>
                                </div>

                                <div className="d-flex flex-row align-items-start justify-content-end">
                                  <div className="card-tools mb-4" id="downloadpurpose">
                                    <UncontrolledDropdown>
                                      <DropdownToggle tag="div" color="transparent">
                                        <a
                                          href="#toggle"
                                          onClick={(ev) => ev.preventDefault()}
                                          className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                        >
                                          <Icon className="d-none d-sm-inline" name="download-cloud" />
                                        </a>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem
                                              onClick={(e) => {
                                                e.preventDefault();
                                                captureChart(
                                                  "trippurpose",
                                                  "downloadpurpose",
                                                  `Trip Purposes Breakdown [${formatDate(
                                                    dateRanges.start
                                                  )} - ${formatDate(dateRanges.end)}]`,
                                                  topPurposesData
                                                );
                                              }}
                                              tag="a"
                                              href="#dropdownitem"
                                              onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                              onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                            >
                                              <span>Export as PNG</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              onClick={(e) => {
                                                e.preventDefault();
                                                captureChartPDF(
                                                  "trippurpose",
                                                  "downloadpurpose",
                                                  `Trip Purposes Breakdown [${formatDate(
                                                    dateRanges.start
                                                  )} - ${formatDate(dateRanges.end)}]`,
                                                  topPurposesData
                                                );
                                              }}
                                              tag="a"
                                              href="#dropdownitem"
                                              onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                              onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                            >
                                              <span>Export as PDF</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                </div>
                              </div>
                              {topPurposesData ? (
                                <div
                                  className="d-flex align-items-center ml-5 p-lg-0 p-md-4 p-sm-5"
                                  style={{ height: "210px" }}
                                >
                                  <ResponsivePie
                                    colors={{ scheme: "paired" }}
                                    data={topPurposesData}
                                    margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    borderColor={{
                                      from: "color",
                                      modifiers: [["darker", 0.2]],
                                    }}
                                    arcLinkLabelsSkipAngle={13}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: "color" }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                      from: "color",
                                      modifiers: [["darker", 2]],
                                    }}
                                    fill={[
                                      {
                                        match: {
                                          id: "ruby",
                                        },
                                        id: "dots",
                                      },
                                      {
                                        match: {
                                          id: "c",
                                        },
                                        id: "dots",
                                      },
                                      {
                                        match: {
                                          id: "go",
                                        },
                                        id: "dots",
                                      },
                                      {
                                        match: {
                                          id: "python",
                                        },
                                        id: "dots",
                                      },
                                      {
                                        match: {
                                          id: "scala",
                                        },
                                        id: "lines",
                                      },
                                      {
                                        match: {
                                          id: "lisp",
                                        },
                                        id: "lines",
                                      },
                                      {
                                        match: {
                                          id: "elixir",
                                        },
                                        id: "lines",
                                      },
                                      {
                                        match: {
                                          id: "javascript",
                                        },
                                        id: "lines",
                                      },
                                    ]}
                                    onClick={(node) => {
                                      redirectToTickets(node.id, node.value, dateRanges.start, dateRanges.end);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div style={{ height: "210px" }} className="text-center mt-2">
                                  No Data Available
                                </div>
                              )}
                            </React.Fragment>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )}

                <Col xxl="6" md="6">
                  <Card className="pr-2 pb-4" id="androidvsios">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <div className="d-flex align-items-start">
                                <h6 className="title">Android vs iOS</h6>
                                <Icon name="chevrons-right ml-1" style={{ fontSize: "20px" }}></Icon>
                              </div>
                              <p>Number of Android and iOS Users</p>
                            </div>

                            <div className="d-flex flex-row align-items-start justify-content-end">
                              <div className="card-tools mb-4" id="downloadandroidvsios">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "androidvsios",
                                              "downloadandroidvsios",
                                              `Andriod vs iOS [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              androidIOSData
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "androidvsios",
                                              "downloadandroidvsios",
                                              `Andriod vs iOS [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              androidIOSData
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>
                          {androidIOSData ? (
                            <div
                              className="d-flex align-items-center ml-5 p-lg-0 p-md-4 p-sm-5"
                              style={{ height: "210px" }}
                            >
                              <ResponsivePie
                                colors={{ scheme: "paired" }}
                                data={androidIOSData}
                                margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                  from: "color",
                                  modifiers: [["darker", 0.2]],
                                }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: "color" }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                  from: "color",
                                  modifiers: [["darker", 2]],
                                }}
                                defs={[
                                  {
                                    id: "dots",
                                    type: "patternDots",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                  },
                                  {
                                    id: "lines",
                                    type: "patternLines",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                  },
                                ]}
                                fill={[
                                  {
                                    match: {
                                      id: "ruby",
                                    },
                                    id: "dots",
                                  },
                                  {
                                    match: {
                                      id: "c",
                                    },
                                    id: "dots",
                                  },
                                  {
                                    match: {
                                      id: "go",
                                    },
                                    id: "dots",
                                  },
                                  {
                                    match: {
                                      id: "python",
                                    },
                                    id: "dots",
                                  },
                                  {
                                    match: {
                                      id: "scala",
                                    },
                                    id: "lines",
                                  },
                                  {
                                    match: {
                                      id: "lisp",
                                    },
                                    id: "lines",
                                  },
                                  {
                                    match: {
                                      id: "elixir",
                                    },
                                    id: "lines",
                                  },
                                  {
                                    match: {
                                      id: "javascript",
                                    },
                                    id: "lines",
                                  },
                                ]}
                                //  legends={[
                                //      {
                                //          anchor: 'bottom',
                                //          direction: 'row',
                                //          justify: false,
                                //          translateX: 0,
                                //          translateY: 56,
                                //          itemsSpacing: 0,
                                //          itemWidth: 100,
                                //          itemHeight: 18,
                                //          itemTextColor: '#999',
                                //          itemDirection: 'left-to-right',
                                //          itemOpacity: 1,
                                //          symbolSize: 18,
                                //          symbolShape: 'circle',
                                //          effects: [
                                //              {
                                //                  on: 'hover',
                                //                  style: {
                                //                      itemTextColor: '#000'
                                //                  }
                                //              }
                                //          ]
                                //      }
                                //  ]}
                                onClick={(node) => {
                                  if (node.id === "Android") {
                                    redirectToAndIOSUsers("Android", 2, dateRanges.start, dateRanges.end);
                                  } else if (node.id === "iOS") {
                                    redirectToAndIOSUsers("iOS", 3, dateRanges.start, dateRanges.end);
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ height: "210px" }} className="text-center mt-2">
                              No Data Available
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>

                {(localStorage.getItem("email") === "marquette@zed.digital" ||
                  localStorage.getItem("email") === "sjcta@zed.digital" ||
                  localStorage.getItem("email") === "lakeerietransit@zed.digital") && (
                  <Col xxl="6" md="6">
                    <PreviewCard>
                      <div
                        style={{
                          paddingTop: 5,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginLeft: 0,
                        }}
                      >
                        <div className="col-12">
                          <div className="row">
                            <div
                              className="card-title"
                              style={{ width: "100%", marginRight: "0", marginBottom: "20px" }}
                            >
                              <h6 className="title" style={{ fontSize: "18px" }}>
                                Tickets Summary
                              </h6>
                            </div>

                            <div
                              className="col-12 col-md-12"
                              //onClick={overViewTicket.trips_booked !== 0 ? () => handleNavigate("1") : null}
                            >
                              <PreviewAltCard bodyClass="py-3">
                                <div className="d-sm-flex align-items-sm-center justify-content-sm-between">
                                  <div className="pb-1 pb-sm-0">
                                    <h6 className="title">Booked Tickets</h6>
                                    {/* <div className="d-flex">
                                      <span>No. of tickets that were booked</span>
                                    </div> */}
                                  </div>
                                  <ul className="rating">
                                    <li>
                                      <h6>{overViewFixedTicket?.trips_booked}</h6>
                                    </li>
                                    <li></li>
                                    <li>
                                      <UserAvatar theme="primary-dim">
                                        <Icon name="ticket-fill" color="primary"></Icon>
                                      </UserAvatar>
                                    </li>
                                  </ul>
                                </div>
                              </PreviewAltCard>
                              <br />
                              <PreviewAltCard bodyClass="py-3">
                                <div className="d-sm-flex align-items-sm-center justify-content-sm-between">
                                  <div className="pb-1 pb-sm-0">
                                    <h6 className="title">Activated Tickets</h6>
                                    {/* <div className="d-flex">
                                      <span>No. of tickets that were activated</span>
                                    </div> */}
                                  </div>
                                  <ul className="rating">
                                    <li>
                                      <h6>{overViewFixedTicket?.trips_activated}</h6>
                                    </li>
                                    <li></li>
                                    <li>
                                      <UserAvatar theme="success-dim">
                                        <Icon name="ticket-fill" color="success"></Icon>
                                      </UserAvatar>
                                    </li>
                                  </ul>
                                </div>
                              </PreviewAltCard>
                              <br />
                              <PreviewAltCard bodyClass="py-3">
                                <div className="d-sm-flex align-items-sm-center justify-content-sm-between">
                                  <div className="pb-1 pb-sm-0">
                                    <h6 className="title">Completed Tickets</h6>
                                    {/* <div className="d-flex">
                                      <span>No. of tickets that were completed</span>
                                    </div> */}
                                  </div>
                                  <ul className="rating">
                                    <li>
                                      <h6>{overViewFixedTicket?.trips_completed}</h6>
                                    </li>
                                    <li></li>
                                    <li>
                                      <UserAvatar theme="danger-dim">
                                        <Icon name="ticket-fill" color="danger"></Icon>
                                      </UserAvatar>
                                    </li>
                                  </ul>
                                </div>
                              </PreviewAltCard>
                            </div>
                            {/* <div
                              className="col-12 col-md-12 mb-5"
                              onClick={overViewTicket.trips_completed !== 0 ? () => handleNavigate("4") : null}
                            ></div> */}
                            {/* <div
                              className="col-12 col-md-12 mb-5"
                              onClick={overViewTicket.trips_cancelled !== 0 ? () => handleNavigate("0") : null}
                            ></div> */}
                            {/* <div
                              className="col-6 col-md-4 mb-5"
                              onClick={overViewTicket.no_show !== 0 ? () => handleNavigate("6") : null}
                            >
                              <PreviewAltCard className="hover-effect">
                                <div
                                  className="d-flex flex-row align-items-center justify-content-between"
                                  style={{ height: "50px" }}
                                >
                                  <div className="d-flex flex-column justify-content-center">
                                    <h6>No Show</h6>
                                    <h4>{user === "ZED Demo" ? 5 : overViewTicket.no_show}</h4>
                                  </div>
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                    <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                    <img src={tickets3} alt={tickets3} style={{ width: "50px" }} />
                                  </div>
                                </div>
                              </PreviewAltCard>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </PreviewCard>
                  </Col>
                )}

                {/* <Col xxl="6" md="6">
                    {!loading && topPurposesData && topPurposesData.length === 0 && (
                      <div className="text-center mt-2">No New and Existing Users</div>
                    )}
                    <Card className="pr-2 pb-4"></Card>
                  </Col> */}
              </Row>

              {/* <Row className="g-gs">
                  <Col xxl="8" md="12">
                    <PreviewCard style={{ height: "400px", width: "100%" }}>
                      <div className="card-head" style={{ width: "100%", marginRight: "0", borderRadius: "5px" }}>
                        <h4 className="title">Tickets Summary </h4>
                      </div>
                      <div
                        style={{
                          paddingTop: 35,
                          paddingBottom: 25,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginLeft: 0,
                        }}
                      >
                        <Row className="g-gs">
                          <div className="col-12">
                            {ticketCounts && ticketCounts.length > 0 ? (
                              <div className="row">
                                {["Activated", "Unactivated", "Validated", "Unvalidated", "Expired", "Total"].map((title, idx) => (
                                  <div key={idx} className="col-6 col-md-4 mb-5">
                                    <div className="d-flex flex-column align-items-center">
                                      <h4>{title}</h4>
                                      <h4>
                                        <CountUp
                                          className="rolling-number"
                                          duration={3}
                                          end={ticketCounts.reduce(
                                            (acc, ticket) => acc + parseInt(ticket[title.toLowerCase()]),
                                            0
                                          )}
                                          style={{
                                            color:
                                              title === "Total"
                                                ? "blue"
                                                : title === "Activated" || title === "Validated"
                                                  ? "green"
                                                  : "red",
                                            fontWeight: 700,
                                          }}
                                        />{" "}
                                      </h4>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>No data found</div>
                            )}
                          </div>
                        </Row>
                      </div>
                    </PreviewCard>
                  </Col>
                </Row> */}

              {/* Tickets Summary */}
              {/* <Row className="g-gs">
                  <Col xxl="8" md="12">
                    <div id="ticketsummary">
                      <PreviewCard style={{ height: "400px", width: "100%" }}>
                        <div className="d-flex flex-start align-items-start justify-content-between">
                          <div className="card-title" style={{ width: "100%", marginRight: "0", borderRadius: "5px" }}>
                            <h6 className="title" style={{ fontSize: "18px" }}>
                              Tickets Summary
                            </h6>
                          </div>

                          <div className="card-tools" id="download4">
                            <UncontrolledDropdown>
                              <DropdownToggle tag="div" color="transparent">
                                <a
                                  href="#toggle"
                                  onClick={(ev) => ev.preventDefault()}
                                  className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                >
                                  <Icon className="d-none d-sm-inline" name="download-cloud" />
                                </a>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        captureChart(
                                          "ticketsummary",
                                          "download4",
                                          `Tickets Summary [${formatDate(dateRanges.start)} - ${formatDate(
                                            dateRanges.end
                                          )}]`,
                                          ticketCounts
                                        );
                                      }}
                                      tag="a"
                                      href="#dropdownitem"
                                      onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                      onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                    >
                                      <span>Export as PNG</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        captureChartPDF(
                                          "ticketsummary",
                                          "download4",
                                          `Tickets Summary [${formatDate(dateRanges.start)} - ${formatDate(
                                            dateRanges.end
                                          )}]`,
                                          ticketCounts
                                        );
                                      }}
                                      tag="a"
                                      href="#dropdownitem"
                                      onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                      onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                    >
                                      <span>Export as PDF</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: 35,
                            paddingBottom: 25,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: 0,
                          }}
                        >
                          
                          <Row className="g-gs">
                            <div className="col-12">
                              {ticketCounts && ticketCounts.length > 0 ? (
                                <div className="row">
                                  {["Activated", "Unactivated", "Validated", "Unvalidated", "Expired", "Total"].map(
                                    (title, idx) => (
                                      <div key={idx} className="col-6 col-md-4 mb-5">
                                        <PreviewAltCard style={{ height: "300px", width: "100%" }}>
                                          <Link
                                            to={`/microtickets?title=${title}`}
                                            style={{
                                              textDecoration: "none",
                                              color: "inherit",
                                              display: "block",
                                              height: "100%",
                                            }}
                                          >
                                            {idx === 0 && (
                                              <img
                                                src={tickets1}
                                                alt={tickets1}
                                                style={{ width: "50px", float: "right", marginLeft: "10px" }}
                                              />
                                            )}
                                            {idx === 1 && (
                                              <img
                                                src={tickets7}
                                                alt={tickets7}
                                                style={{ width: "50px", float: "right", marginLeft: "10px" }}
                                              />
                                            )}
                                            {idx === 2 && (
                                              <img
                                                src={tickets4}
                                                test
                                                alt={tickets4}
                                                style={{ width: "50px", float: "right", marginLeft: "10px" }}
                                              />
                                            )}
                                            {idx === 3 && (
                                              <img
                                                src={tickets6}
                                                alt={tickets6}
                                                style={{ width: "60px", float: "right", marginLeft: "10px" }}
                                              />
                                            )}
                                            {idx === 4 && (
                                              <img
                                                src={tickets5}
                                                alt={tickets5}
                                                style={{
                                                  marginTop: -10,
                                                  width: "70px",
                                                  float: "right",
                                                  marginRight: "-9px",
                                                }}
                                              />
                                            )}
                                            {idx === 5 && (
                                              <img
                                                src={tickets3}
                                                alt={tickets3}
                                                style={{ width: "70px", float: "right", marginRight: "-10px" }}
                                              />
                                            )}
                                            <div className="d-flex flex-column align-items-left">
                                              <h6>{title}</h6>
                                              <h4>
                                                <CountUp
                                                  className="rolling-number"
                                                  duration={3}
                                                  end={ticketCounts.reduce(
                                                    (acc, ticket) => acc + parseInt(ticket[title.toLowerCase()]),
                                                    0
                                                  )}
                                                  style={{
                                                    color:
                                                      title === "Total"
                                                        ? "darkblue"
                                                        : title === "Unactivated"
                                                        ? " blue" 
                                                        : title === "Activated"
                                                        ? "green"
                                                        : "red",
                                                    fontWeight: 700,
                                                  }}
                                                />{" "}
                                              </h4>
                                            </div>
                                          </Link>
                                        </PreviewAltCard>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="text-center mt-2">No Ticket Data</div>
                              )}
                            </div>
                          </Row>
                        </div>
                      </PreviewCard>
                    </div>
                  </Col>
                </Row> */}

              {localStorage.getItem("email") !== "marquette@zed.digital" &&
                localStorage.getItem("email") !== "sjcta@zed.digital" &&
                localStorage.getItem("email") !== "lakeerietransit@zed.digital" && (
                  <Row className="g-gs">
                    <Col xxl="12" md="12">
                      <PreviewCard>
                        <div
                          style={{
                            paddingTop: 35,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: 0,
                          }}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div
                                className="card-title"
                                style={{ width: "100%", marginRight: "0", marginBottom: "20px" }}
                              >
                                <h6 className="title" style={{ fontSize: "18px" }}>
                                  Tickets Summary
                                </h6>
                              </div>
                              <div
                                className="col-6 col-md-4 mb-2"
                                onClick={overViewTicket.total_length !== 0 ? () => handleNavigate("all") : null}
                              >
                                <PreviewAltCard className="hover-effect">
                                  <div
                                    className="d-flex flex-row align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6>Total Trips</h6>
                                      <h4>{user === "ZED Demo" ? 8 : overViewTicket.total_length}</h4>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                      <img src={tickets1} alt={tickets1} style={{ width: "50px" }} />
                                    </div>
                                  </div>
                                </PreviewAltCard>
                              </div>
                              <div
                                className="col-6 col-md-4 mb-5"
                                onClick={overViewTicket.trips_booked !== 0 ? () => handleNavigate("1") : null}
                              >
                                <PreviewAltCard className="hover-effect">
                                  <div
                                    className="d-flex flex-row align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6>Upcoming Trips</h6>
                                      <h4>{user === "ZED Demo" ? 6 : overViewTicket.trips_booked}</h4>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                      <img src={tickets7} alt={tickets7} style={{ width: "50px" }} />
                                    </div>
                                  </div>
                                </PreviewAltCard>
                              </div>
                              <div
                                className="col-6 col-md-4 mb-5"
                                onClick={overViewTicket.trips_completed !== 0 ? () => handleNavigate("4") : null}
                              >
                                <PreviewAltCard className="hover-effect">
                                  <div
                                    className="d-flex flex-row align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6>Trips Completed</h6>
                                      <h4>{user === "ZED Demo" ? 4 : overViewTicket.trips_completed}</h4>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                      <img src={tickets1} alt={tickets1} style={{ width: "50px" }} />
                                    </div>
                                  </div>
                                </PreviewAltCard>
                              </div>
                              <div
                                className="col-6 col-md-4 mb-5"
                                onClick={overViewTicket.trips_cancelled !== 0 ? () => handleNavigate("0") : null}
                              >
                                <PreviewAltCard className="hover-effect">
                                  <div
                                    className="d-flex flex-row align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6>Trips Cancelled</h6>
                                      <h4>{user === "ZED Demo" ? 3 : overViewTicket.trips_cancelled}</h4>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                      <img src={tickets4} alt={tickets4} style={{ width: "50px" }} />
                                    </div>
                                  </div>
                                </PreviewAltCard>
                              </div>
                              <div
                                className="col-6 col-md-4 mb-5"
                                onClick={overViewTicket.no_show !== 0 ? () => handleNavigate("6") : null}
                              >
                                <PreviewAltCard className="hover-effect">
                                  <div
                                    className="d-flex flex-row align-items-center justify-content-between"
                                    style={{ height: "50px" }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6>No Show</h6>
                                      <h4>{user === "ZED Demo" ? 5 : overViewTicket.no_show}</h4>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <Icon name="chevrons-right mb-2" style={{ fontSize: "20px" }}></Icon>
                                      <img src={tickets3} alt={tickets3} style={{ width: "50px" }} />
                                    </div>
                                  </div>
                                </PreviewAltCard>
                              </div>
                            </div>
                          </div>
                        </div>
                      </PreviewCard>
                    </Col>
                  </Row>
                )}

              {/* <Row className="g-gs">
                <Col xxl="12" md="12">
                  <div>
                    <PreviewAltCard>
                      <SalesOverview />
                    </PreviewAltCard>
                  </div>
                </Col>
              </Row> */}
              <Row className="g-gs">
                <Col xxl="12" md="12">
                  <Card id="co2emissions">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <div className="d-flex align-items-start">
                                <h6 className="title">CO2 Emissions</h6>
                              </div>
                              <p>Choosing public transport or walking has reduced CO2 emissions.</p>
                            </div>

                            <div className="d-flex flex-row align-items-start Justify-content-end">
                              <div className="total-count">
                                <div className="title">Total emissions reduced</div>
                                <div className="amount">
                                  <h2 style={{ textAlign: "center", color: "#D83A56" }}>
                                    <span className="amount lg" style={{ color: "red" }}>
                                      {totalCO2Count.toFixed(2)}
                                      <small> lbs</small>
                                    </span>
                                  </h2>
                                </div>
                              </div>

                              <div className="card-tools ml-2" id="downloadCO2">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "co2emissions",
                                              "downloadCO2",
                                              `CO2 Emissions [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              dataCO22
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "co2emissions",
                                              "downloadCO2",
                                              `CO2 Emissions [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              dataCO22
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>

                          <ul className="nk-ecwg8-legends"></ul>
                          <div className="nk-ecwg8-ck">
                            {dataCO22 ? (
                              <Line
                                className="line-chart"
                                data={dataCO22}
                                options={{
                                  legend: { display: false },
                                  maintainAspectRatio: false,
                                  tooltips: {
                                    enabled: true,
                                    callbacks: {
                                      title: function (tooltipItems, data) {
                                        return data.labels[tooltipItems[0].index];
                                      },
                                      label: function (tooltipItem, data) {
                                        return `Count: ${tooltipItem.yLabel.toFixed(2)}`;
                                      },
                                    },
                                    backgroundColor: "#1c2b46",
                                    titleFontSize: 13,
                                    titleFontColor: "#fff",
                                    titleMarginBottom: 6,
                                    bodyFontColor: "#fff",
                                    bodyFontSize: 12,
                                    bodySpacing: 4,
                                    yPadding: 10,
                                    xPadding: 10,
                                    footerMarginTop: 0,
                                    displayColors: false,
                                  },
                                  scales: {
                                    yAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Count \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        ticks: {
                                          beginAtZero: true,
                                          fontSize: 11,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                          callback: function (value, index, values) {
                                            return value;
                                          },
                                          min: 0,
                                          stepSize: 3000,
                                        },
                                        gridLines: {
                                          color: "rgba(82, 100, 132, 0.2)",
                                          tickMarkLength: 0,
                                          zeroLineColor: "rgba(82, 100, 132, 0.2)",
                                        },
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        display: true,
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Date \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        ticks: {
                                          fontSize: 9,
                                          fontColor: "#9eaecf",
                                          padding: 10,
                                          callback: function (value, index, values) {
                                            if (
                                              data &&
                                              data.labels &&
                                              Array.isArray(data.labels) &&
                                              index < data.labels.length
                                            ) {
                                              return data.labels[index];
                                            } else {
                                              return "";
                                            }
                                          },
                                        },
                                        gridLines: {
                                          color: "transparent",
                                          tickMarkLength: 0,
                                          zeroLineColor: "transparent",
                                        },
                                      },
                                    ],
                                  },
                                  elements: {
                                    line: {
                                      tension: 0,
                                    },
                                  },
                                }}
                              />
                            ) : (
                              <div className="text-center mt-2">No CO2 Emissions Data Available</div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
            {/* <Row className="g-gs">
                  <Col xxl="8">
                    <Card className="card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Purchase Fare by Type</h6>
                          </div>
                        </div>
                      </div>
                      <div className="nk-tb-list mt-6">
                        <React.Fragment>
                          <DataTableHead>
                            <DataTableRow>
                              <span>Date</span>
                            </DataTableRow>
                            {fareNames.map((fare) => (
                              <DataTableRow key={fare.fareid}>
                                <span>{fare.farename}</span>
                              </DataTableRow>
                            ))}
                          </DataTableHead>
                          {fareCounts.length === 0 ? (
                            <DataTableItem>
                              <DataTableRow>
                                <span className="text-center">No data found</span>
                              </DataTableRow>
                            </DataTableItem>
                          ) : (
                            defaultDates.map((date, index) => (
                              <DataTableItem key={index}>
                                <DataTableRow>
                                  <span className="tb-sub">{date}</span>
                                </DataTableRow>
                                {Array.from({ length: fareNames.length }, (_, i) => (
                                  <DataTableRow key={i}>
                                    <span style={{ textAlign: "center", display: "block" }}>
                                      {Math.floor(Math.random() * 10)}
                                    </span>
                                  </DataTableRow>
                                ))}
                              </DataTableItem>
                            ))

                          )}
                        </React.Fragment>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row className="g-gs">
                  <Col xxl="8">
                    <Card className="card-full">
                      <div className="card-inner">
                        <div className="card-title-group">
                          <div className="card-title">
                            <h6 className="title">Tickets Status</h6>
                          </div>
                        </div>
                      </div>
                      <div className="nk-tb-list mt-n2">
                        {ticketCounts && ticketCounts.length > 0 ? (
                          <React.Fragment>
                            <DataTableHead>
                              <DataTableRow>
                                <p class="fw-bold" style={{ textAlign: "center", fontWeight: "bold", fontSize: "14px", fontColor: "#526484" }}>Date</p>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Activated</b>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Unactivated</b>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Validated</b>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Unvalidated</b>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Expired</b>
                              </DataTableRow>
                              <DataTableRow>
                                <b>Total</b>
                              </DataTableRow>
                            </DataTableHead>
                            {ticketCounts.map((ticket, idx) => (
                              <DataTableItem key={idx}>
                                <DataTableRow>
                                  <span className="tb-sub">
                                  <b><Moment format="MMMM Do YYYY">{ticket.date}</Moment></b>
                                  </span>
                                </DataTableRow>
                                <DataTableRow>
                                  <strong>{ticket.activated}</strong>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{ticket.unactivated}</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{ticket.validated}</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{ticket.unvalidated}</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{ticket.expired}</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span>{ticket.total}</span>
                                </DataTableRow>
                              </DataTableItem>
                            ))}
                          </React.Fragment>
                        ) : (
                          <div className="no-data">No data found</div>
                        )}
                      </div>
                    </Card>{" "}
                  </Col>
                </Row> */}

            <div id="allreports2">
              <Row className="g-gs">
                <Col xxl="12" md="12 ">
                  <Card id="ticketstatus">
                    <div className="nk-ecwg nk-ecwg2">
                      <div className="card-inner">
                        <React.Fragment>
                          <div
                            className="card-title-group mb-3"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="card-title">
                              <h6 className="title">Tickets Status</h6>
                              <p>Number of Activated, Validated and Expired tickets in a day</p>
                            </div>

                            <div className="d-flex flex-column align-items-center justify-content-end">
                              <div className="card-tools" id="download5">
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="div" color="transparent">
                                    <a
                                      href="#toggle"
                                      onClick={(ev) => ev.preventDefault()}
                                      className="btn btn-danger btn-dim d-none d-sm-inline-flex"
                                    >
                                      <Icon className="d-none d-sm-inline" name="download-cloud" />
                                    </a>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChart(
                                              "ticketstatus",
                                              "download5",
                                              `Tickets Status [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              ticketCounts
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PNG</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            captureChartPDF(
                                              "ticketstatus",
                                              "download5",
                                              `Tickets Status [${formatDate(dateRanges.start)} - ${formatDate(
                                                dateRanges.end
                                              )}]`,
                                              ticketCounts
                                            );
                                          }}
                                          tag="a"
                                          href="#dropdownitem"
                                          onMouseEnter={(e) => e.target.classList.add("text-danger")}
                                          onMouseLeave={(e) => e.target.classList.remove("text-danger")}
                                        >
                                          <span>Export as PDF</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>

                              <div className="total-count" style={{ textAlign: "right" }}>
                                <div style={{ width: "120px", height: "120px", marginBottom: -80 }}>
                                  <Lottie animationData={animationData} loop autoplay speed={0.7} />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ marginBottom: 30, marginTop: 20 }}>
                            <div className="analytic-ov">
                              <div className="analytic-data-group analytic-ov-group g-3">
                                <div className="analytic-data analytic-ov-data">
                                  <div className="title">Total Activated Tickets</div>
                                  <div className="amount" style={{ color: "red" }}>
                                    {ticketCounts.length > 0 ? totalActivatedTickets : 0}
                                  </div>
                                </div>
                                <div className="analytic-data analytic-ov-data">
                                  <div className="title">Total Validated Tickets</div>
                                  <div className="amount" style={{ color: "green" }}>
                                    {ticketCounts.length > 0 ? totalValidatedTickets : 0}
                                  </div>
                                </div>
                                <div className="analytic-data analytic-ov-data">
                                  <div className="title">Total Expired Tickets</div>
                                  <div className="amount" style={{ color: "blue" }}>
                                    {ticketCounts.length > 0 ? totalExpiredTickets : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ height: 300 }}>
                            {ticketCounts.length > 0 &&
                            (totalActivatedTickets > 0 || totalValidatedTickets > 0 || totalExpiredTickets > 0) ? (
                              <Line
                                className="analytics-line-large"
                                data={chartData3}
                                options={{
                                  legend: {
                                    display: false,
                                    labels: {
                                      boxWidth: 12,
                                      padding: 20,
                                      fontColor: "#6783b8",
                                    },
                                  },
                                  maintainAspectRatio: false,
                                  tooltips: {
                                    enabled: true,
                                    backgroundColor: "#1c2b46",
                                    titleFontSize: 13,
                                    titleFontColor: "#fff",
                                    titleMarginBottom: 6,
                                    bodyFontColor: "#fff",
                                    bodyFontSize: 12,
                                    bodySpacing: 4,
                                    yPadding: 10,
                                    xPadding: 10,
                                    footerMarginTop: 0,
                                    displayColors: false,
                                  },
                                  scales: {
                                    yAxes: [
                                      {
                                        display: true,
                                        ticks: {
                                          beginAtZero: true,
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                          padding: 8,
                                          stepSize: 100,
                                        },
                                        gridLines: {
                                          color: "rgba(82, 100, 132, 0.2)",
                                          tickMarkLength: 0,
                                          zeroLineColor: "rgba(82, 100, 132,0.2)",
                                        },
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Count \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                      },
                                    ],
                                    xAxes: [
                                      {
                                        display: true,
                                        ticks: {
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                        gridLines: {
                                          display: false,
                                        },
                                        scaleLabel: {
                                          display: true,
                                          labelString: "Date \u2192",
                                          fontSize: 12,
                                          fontColor: "#9eaecf",
                                        },
                                      },
                                    ],
                                  },
                                  elements: {
                                    line: {
                                      tension: 0, // Set line tension to 0 for straight lines
                                    },
                                  },
                                }}
                              ></Line>
                            ) : (
                              <div className="text-center mt-5">No Tickets Available</div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="g-gs">
                <TicketStatusMetrics dateRanges={dateRanges} />
              </Row>
              {process.env.REACT_APP_PANEL_TEXT === "MDOT" && (
                <Row className="g-gs">
                  <UserAccessMetrics dateRanges={dateRanges} />
                </Row>
              )}
            </div>
          </Block>
        )}
        <ToastContainer />
      </Content>
    </React.Fragment>
  );
};

const exportColumns = [
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Activated",
    selector: (row) => row.activated,
    sortable: true,
  },
  {
    name: "Unactivated",
    selector: (row) => row.unactivated,
    sortable: true,
  },
  {
    name: "Validated",
    selector: (row) => row.validated,
    sortable: true,
  },
  {
    name: "Unvalidated",
    selector: (row) => row.unvalidated,
    sortable: true,
  },
  {
    name: "Expired",
    selector: (row) => row.expired,
    sortable: true,

    sortField: "ActivatedDate",
  },
  {
    name: "Total",
    selector: (row) => row.total,
    sortable: true,
  },
];

const cardStyle = {
  height: "50px",
  width: "100%",
  backgroundColor: "#FFFFFF", // Default background color
  transition: "background-color 0.3s ease", // Smooth transition for background color change
  ":hover": {
    backgroundColor: "#FCECEB", // Background color on hover
  },
};

export default Home;
