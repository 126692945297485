import React, { useEffect, useState } from "react";
import MyDropdown from "./MyDropDown";
import { Link, useHistory } from "react-router-dom";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  BlockBetween,
  PaginationComponent,
  Col,
  ReactDataTable,
  Button,
  Row,
} from "../components/Component";
import axios from "axios";

import styled from "styled-components";
import DatePicker from "react-datepicker";

import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import { useSelector } from "react-redux";
import { user_id } from "../redux/userSlice";
import { Form, FormGroup, Label, Input, Card, CardBody } from "reactstrap";
import { successAlert, failureAlert } from "../utils/Utils";
import MyDropdownMaster from "./MyDropDownMaster";
import Swal from "sweetalert2";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { role } from "../redux/userSlice";

const CreateSchedule = () => {
  const history = useHistory();
  const INITIAL_ADD_FORM = [
    {
      day: "Monday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    {
      day: "Tuesday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    {
      day: "Wednesday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    {
      day: "Thursday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    {
      day: "Friday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    {
      day: "Saturday",
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    },
    // {
    //   day: "Sunday",
    //   coach_no: "",
    //   line_no: "",
    //   time_in: "",
    //   time_out: "",
    //   break_in: "",
    //   break_out: "",
    //   total_hours: "",
    //   comments: "",
    // },
  ];

  const redirectToRunCuttingPage = () => {
    history.push("/run-cutting-scheduler");
  };
  const [driverId, setDriverId] = useState();
  const [masterId, setMasterId] = useState();
  const [driverList, setDriverList] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [formFields, setFormFields] = useState(INITIAL_ADD_FORM);
  const [isClicked, setIsClicked] = useState(false);

  const [formData, setFormData] = useState(INITIAL_ADD_FORM);
  const [fieldCounts, setFieldCounts] = useState({});
  const mondayCount = formFields.reduce((count, item) => {
    if (item.day === "Monday") {
      return count + 1;
    }
    return count;
  }, 0);
  const tuesdayCount = formFields.reduce((count, item) => {
    if (item.day === "Tuesday") {
      return count + 1;
    }
    return count;
  }, 0);
  const wednesdayCount = formFields.reduce((count, item) => {
    if (item.day === "Wednesday") {
      return count + 1;
    }
    return count;
  }, 0);
  const thursdayCount = formFields.reduce((count, item) => {
    if (item.day === "Thursday") {
      return count + 1;
    }
    return count;
  }, 0);
  const fridayCount = formFields.reduce((count, item) => {
    if (item.day === "Friday") {
      return count + 1;
    }
    return count;
  }, 0);
  const saturdayCount = formFields.reduce((count, item) => {
    if (item.day === "Saturday") {
      return count + 1;
    }
    return count;
  }, 0);
  // const sundayCount = formFields.reduce((count, item) => {
  //   if (item.day === "Sunday") {
  //     return count + 1;
  //   }
  //   return count;
  // }, 0);

  const [coachL, setCoachL] = useState([]);
  const [linesL, setLinesL] = useState([]);
  // const coachL = [
  //   "Select",
  //   1055,
  //   1056,
  //   1060,
  //   1070,
  //   1071,
  //   1072,
  //   1073,
  //   1074,
  //   1075,
  //   1076,
  //   1077,
  //   1078,
  //   "off",
  //   "Day Off",
  //   "TBD",
  // ];

  const addFields = (day, index) => {
    let newField = {
      day: day,
      coach_no: "",
      line_no: "",
      time_in: "",
      time_out: "",
      break_in: "",
      break_out: "",
      total_hours: "",
      comments: "",
    };
    console.log(newField);

    // Splice the new field at the desired index
    const updatedFields = [...formFields];
    updatedFields.splice(index + 1, 0, newField);

    setFormFields(updatedFields);
  };

  const removeFields = (index) => {
    console.log(index);
    console.log("Inside remove fields");
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };
  const LineL = ["Select", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "1 & 4", "9 & 10"];
  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;

    const timeIn = data[index].time_in;
    const timeOut = data[index].time_out;
    // const breakIn = data[index].break_in;
    // const breakOut = data[index].break_out;

    // Compare and update the times
    // if (timeIn && timeOut && timeOut < timeIn) {
    //   data[index].time_out = timeIn; // Set time_out to be greater than time_in
    // }

    // if (timeIn && breakIn && breakIn < timeIn) {
    //   data[index].break_in = timeIn; // Set break_in to be greater than time_in
    // }

    // if (timeIn && breakOut && breakOut < timeIn) {
    //   data[index].break_out = timeIn; // Set break_out to be greater than time_in
    // }

    // if (breakIn && breakOut && breakOut < breakIn) {
    //   data[index].break_out = breakIn; // Set break_out to be greater than break_in
    // }

    // if (breakOut && timeOut && timeOut < breakOut) {
    //   data[index].time_out = breakOut; // Set time_out to be greater than break_out
    // }

    // Calculate total hours
    if (timeOut instanceof Date && !isNaN(timeOut.getTime()) && timeIn instanceof Date && !isNaN(timeIn.getTime())) {
      // if (breakIn && breakOut) {
      //   const diffInMilliseconds = timeOut.getTime() - timeIn.getTime();
      //   const breakInMilliseconds = breakOut.getTime() - breakIn.getTime();
      //   const totalHours = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
      //   const breakHours = breakInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

      //   data[index].total_hours = (totalHours - breakHours).toFixed(2); // Round to 2 decimal places
      // } else {
      if (timeOut instanceof Date && !isNaN(timeOut.getTime()) && timeIn instanceof Date && !isNaN(timeIn.getTime())) {
        const diffInMilliseconds = timeOut.getTime() - timeIn.getTime();
        // const totalHours = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
        // data[index].total_hours = totalHours.toFixed(2); // Round to 2 decimal places
        const totalHours = diffInMilliseconds / (1000 * 60 * 60);
        let hours = Math.floor(totalHours);
        let minutes = Math.round((totalHours - hours) * 60);

        if (minutes === 60) {
          hours += 1;
          minutes = 0;
        }

        data[index].total_hours =
          hours === 0 && minutes !== 0 ? `${minutes}m` : minutes === 0 ? `${hours}h` : `${hours}h ${minutes}m`;
      }
      // }
    }
    console.log(data);
    setFormFields(data);
  };

  const onChangeHandler = (data) => {
    console.log("Handler");
    console.log(data);
    setDriverId(data.value);
  };
  const onChangeMasterIdHandler = (data) => {
    console.log("Handler");
    console.log(data);
    setMasterId(data.value);
  };

  useEffect(() => {
    const getMasterList = async () => {
      try {
        //const response = await axios.get("/getMaster?venueRefId=" + userId);
        let response;
        if (userrole === 3 && clientValueId) {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getMaster?venueRefId=${clientValueId}`);
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getMaster?venueRefId=${client_id}`);
        }

        if (response.status === 200) {
          setMasterList([...response.data]);
        } else {
          setMasterList([]);
        }
      } catch (err) {
        console.log("Error message:", err);
        setMasterList([]);
      }
    };
    getMasterList();
  }, [clientValueId]);

  useEffect(() => {
    const getCoachList = async () => {
      try {
        //const response = await axios.get("/getMaster?venueRefId=" + userId);
        let response;
        if (userrole === 3 && clientValueId) {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getdeviceDetails?id=${clientValueId}`);
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getdeviceDetails?id=${client_id}`);
        }

        if (response.status === 200) {
          const filteredData = response.data.map((item) => item.vehicleNo);
          console.log("Filtered Data:", filteredData);
          setCoachL([...filteredData]);
        } else {
          setCoachL([]);
        }
      } catch (err) {
        console.log("Error message:", err);
        setCoachL([]);
      }
    };
    getCoachList();
  }, [clientValueId]);

  useEffect(() => {
    const getLineList = async () => {
      try {
        let response;
        const axiosInstance = axios.create();

        if (userrole === 3 && clientValueId) {
          response = await axiosInstance.get(
            `${process.env.REACT_APP_FLEET_URL}api/Routes/GetAllRoutes?clientId=${clientValueId}`
          );
        } else {
          response = await axiosInstance.get(
            `${process.env.REACT_APP_FLEET_URL}api/Routes/GetAllRoutes?clientId=${client_id}`
          );
        }

        if (response.status === 200) {
          const filteredData = response.data.routesList.map((item) => item.routeId);
          console.log("lines Data:", filteredData);
          setLinesL([...filteredData]);
        } else {
          setLinesL([]);
        }
      } catch (err) {
        console.log("Error message:", err);
        setLinesL([]);
      }
    };

    getLineList();
  }, [clientValueId]);

  useEffect(() => {
    const getDriverList = async () => {
      try {
        //const response = await axios.get("/getDriverList?venueRefId=" + userId);
        let response;
        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${clientValueId}`
          );
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${client_id}`);
        }

        if (response.status === 200) {
          setDriverList([...response.data]);
        } else {
          setDriverList([]);
        }
      } catch (err) {
        console.log("Error message:", err);
      }
    };
    getDriverList();
  }, [clientValueId]);

  const calculateTotalHoursForRow = (item) => {
    const timeIn = item.time_in;
    const timeOut = item.time_out;
    const breakIn = item.break_in;
    const breakOut = item.break_out;

    if (
      timeIn instanceof Date &&
      !isNaN(timeIn.getTime()) &&
      timeOut instanceof Date &&
      !isNaN(timeOut.getTime()) &&
      breakIn instanceof Date &&
      !isNaN(breakIn.getTime()) &&
      breakOut instanceof Date &&
      !isNaN(breakOut.getTime()) &&
      timeOut > timeIn &&
      breakOut > breakIn
    ) {
      const diffInMilliseconds = timeOut.getTime() - timeIn.getTime();
      const breakInMilliseconds = breakOut.getTime() - breakIn.getTime();

      const totalHours = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
      const breakHours = breakInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

      return (totalHours - breakHours).toFixed(2); // Round to 2 decimal places
    }

    return "";
  };

  const totalHoursSum = formData.reduce((acc, item) => {
    const totalHoursForRow = calculateTotalHoursForRow(item);
    if (totalHoursForRow !== "") {
      acc += parseFloat(totalHoursForRow);
    }
    return acc;
  }, 0);

  const resetform = () => {
    console.log("inside reset");
    setFormData([...INITIAL_ADD_FORM]);
    setFormFields(INITIAL_ADD_FORM);
  };
  const onAddSubmit = async (data, driverId) => {
    try {
      setIsClicked(true);
      console.log(data);
      const hasFilledField = data.some((schedule) =>
        Object.entries(schedule).every(
          ([key, value]) =>
            ["day", "break_in", "break_out", "comments"].includes(key) || (value && value !== "" && value !== "Select")
        )
      );

      const hasEmptyField = data.some((schedule) => {
        const relevantEntries = Object.entries(schedule).filter(
          ([key]) => !["day", "break_in", "break_out", "comments"].includes(key)
        );

        const allEmpty = relevantEntries.every(([, value]) => value === "" || value === "Select");

        const allFilled = relevantEntries.every(([, value]) => value && value !== "" && value !== "Select");

        return !(allEmpty || allFilled);
      });

      const sameTimeInOut = data.some((schedule) => {
        const timeIn = new Date(schedule.time_in);
        const timeOut = new Date(schedule.time_out);

        return timeIn.getTime && timeOut.getTime && timeIn.getTime() === timeOut.getTime() && schedule.time_in !== "";
      });

      if (!driverId) {
        failureAlert("Please select a driver");
        setIsClicked(false);
        return;
      } else if (!hasFilledField) {
        // Show failure alert if validation fails
        failureAlert("Please set up a schedule for at least one day.");
        setIsClicked(false);
        return; // Stop further execution
      } else if (hasEmptyField) {
        failureAlert("Please fill all the fields for the necessary days.");
        setIsClicked(false);
        return;
      } else if (sameTimeInOut) {
        failureAlert("Time In and Time Out cannot be same");
        setIsClicked(false);
        return;
      }

      let payload = { driver_id: driverId, schedules: data };
      console.log(data.length);
      console.log("Add schedule payload", payload);
      const res = await axios.post(`${process.env.REACT_APP_CONFIG_BASE_URL}v3/addschedule`, payload);
      if (res.status === 201) {
        // const newTrackers = [...trackers];
        // newTrackers.push(js);
        // console.log(newTrackers);
        // setTrackers(newTrackers);
        // setCreatedSchedule(true);
        // setView({
        //   edit: false,
        //   add: false,
        //   diagnose: false,
        // });
        resetform();
        successAlert("Schedule created successfully");
        redirectToRunCuttingPage();
        // onSubmitHandler();
      } else {
        resetform();
        const err1 = res.data.message;
        failureAlert(err1);
      }
    } catch (err) {
      failureAlert("Please try again later");
      console.log(err);
    } finally {
      setIsClicked(false);
    }
  };

  const getImportedData = async () => {
    //const response = await axios.get("/getMasterDriverSchedule?driver_id=" + driverId + "&m_id=" + masterId);
    const response = await axios.get(
      `${process.env.REACT_APP_CONFIG_BASE_URL}/getMasterDriverSchedule?driver_id=` + driverId + `&m_id=85`
    );
    if (response.status === 200) {
      setFormData(response.data);
      setFormFields(response.data);
    }
    return response.data;
  };
  const importConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to import from the schedule?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, import it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const iData = getImportedData();
        console.log(iData);
        Swal.fire("Imported!", "Schedule has been successfully imported", "success");
      }
    });
  };

  const throwDriverError = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Please select a driver",
      focusConfirm: false,
    });
  };
  const throwMasterError = () => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Please select Master",
      focusConfirm: false,
    });
  };
  const importFromMaster = () => {
    if (driverId) {
      if (masterId) {
        importConfirmation();
      } else {
        throwMasterError();
      }
    } else {
      throwDriverError();
    }
  };
  return (
    <React.Fragment>
      <Head title="Create Schedule"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle className="text-primary" page tag="h3">
                Create Schedule
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <BlockHeadContent>
                        <Link to={`${process.env.PUBLIC_URL}/run-cutting-scheduler`}>
                          <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                            <Icon name="arrow-left"></Icon>
                            <span>Back</span>
                          </Button>
                          <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                            <Icon name="arrow-left"></Icon>
                          </Button>
                        </Link>
                      </BlockHeadContent>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Card>
          <CardBody>
            <Block>
              {/* <h3>Create Schedule </h3> */}
              <Row>
                <Col className="m-2">
                  <MyDropdown onChangeHandle={onChangeHandler} driverlist={driverList} />
                </Col>
                {/* <Col className="m-2">
                  <MyDropdownMaster onChangeHandle={onChangeMasterIdHandler} masterList={masterList} />
                </Col> */}
                {/* <Col className="m-2">
                  <Button onClick={importFromMaster} color="primary">
                    <Icon name="download" />
                    <span>Import from Master</span>
                  </Button>
                </Col> */}
              </Row>

              {/* <MyDropDown onChangeHandle={onChangeHandler} driverlist={driverList} /> */}
              {/* <div className="m-2" style={{ textAlign: "right" }}>
                <h5>
                  Total Hours: <span style={{ fontSize: "25px" }}>{totalHoursSum.toFixed(2)}</span>
                </h5>
              </div> */}
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <StyledTableHeader
                      className="table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      scope="col"
                    >
                      Day
                    </StyledTableHeader>
                    <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      scope="col"
                    >
                      Coach No
                    </StyledTableHeader>
                    <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle", width: "30%" }}
                      scope="col"
                    >
                      Line Name
                    </StyledTableHeader>

                    <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle", width: "13%" }}
                      scope="col"
                    >
                      Time In
                    </StyledTableHeader>
                    {/* <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      scope="col"
                    >
                      Break
                    </StyledTableHeader>
                    <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      scope="col"
                    >
                      Break
                    </StyledTableHeader> */}
                    <StyledTableHeader
                      className=" table-dark"
                      style={{ textAlign: "center", verticalAlign: "middle", width: "13%" }}
                      scope="col"
                    >
                      Time Out
                    </StyledTableHeader>
                    <StyledTableHeader
                      className=" table-dark"
                      cla
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                      scope="col"
                    >
                      Total Hours
                    </StyledTableHeader>
                  </tr>
                </thead>
                <tbody>
                  {formFields.length > 0
                    ? formFields.map((item, index) => {
                        const isOff = item.coach_no === "off" || item.coach_no === "Day Off";
                        const count = fieldCounts[item.day] || 0;
                        const shouldDisplayRemoveButton = index !== 0 && count > 1;
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <th style={{ textAlign: "center", verticalAlign: "middle" }} scope="row">
                                {item.day}
                              </th>
                              <StyledTableData
                                style={{ width: "180px", textAlign: "center", verticalAlign: "middle" }}
                                scope="col"
                              >
                                <div className="form-control-select">
                                  <Input
                                    type="select"
                                    name="coach_no"
                                    id="coach_no"
                                    value={item.coach_no}
                                    onChange={(event) => {
                                      handleFormChange(event, index);
                                    }}
                                    // disabled={isOff}
                                  >
                                    <option value="Select">Select</option>
                                    {coachL.map((item) => (
                                      <option key={item}>{item}</option>
                                    ))}
                                  </Input>
                                </div>
                              </StyledTableData>
                              <StyledTableData style={{ width: "180px", textAlign: "center", verticalAlign: "middle" }}>
                                <div className="form-control-select">
                                  <Input
                                    type="select"
                                    name="line_no"
                                    id="line_no"
                                    value={item.line_no}
                                    onChange={(event) => {
                                      handleFormChange(event, index);
                                    }}
                                    disabled={isOff}
                                  >
                                    <option value="Select">Select</option>
                                    {linesL.map((item) => (
                                      <option key={item}>{item}</option>
                                    ))}
                                  </Input>
                                </div>
                              </StyledTableData>
                              <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                                <div className="form-control-select">
                                  <DatePicker
                                    selected={item.time_in ? new Date(item.time_in) : null}
                                    onChange={(date) => {
                                      handleFormChange({ target: { name: "time_in", value: date } }, index);
                                    }}
                                    name="time_in"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    // value={item.start_time}
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="form-control date-picker"
                                    autoComplete="off"
                                    disabled={isOff}
                                  />
                                </div>
                              </StyledTableData>
                              {/** 
                            <StyledTableData hidden style={{ textAlign: "center", verticalAlign: "middle" }}>
                              <div className="form-control-select">
                                <DatePicker
                                  selected={item.break_in ? new Date(item.break_in) : null}
                                  onChange={(date) => {
                                    handleFormChange({ target: { name: "break_in", value: date } }, index);
                                  }}
                                  name="break_in"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  // value={item.break_in}
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control date-picker"
                                  autoComplete="off"
                                  // disabled={isOff || item.time_in === ""}
                                  minTime={item.time_in ? new Date(item.time_in) : undefined} // Set the minimum time based on start_time
                                  maxTime={new Date(9999, 0, 1, 23, 59)}
                                />
                              </div>
                            </StyledTableData>
                            <StyledTableData hidden style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {" "}
                              <div className="form-control-select">
                                <DatePicker
                                  selected={item.break_out ? new Date(item.break_out) : null}
                                  onChange={(date) => {
                                    handleFormChange({ target: { name: "break_out", value: date } }, index);
                                  }}
                                  name="break_out"
                                  showTimeSelect
                                  showTimeSelectOnly
                                  // value={item.break_out}
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control date-picker"
                                  autoComplete="off"
                                  // disabled={isOff || item.break_in === ""}
                                  minTime={item.break_in ? new Date(item.break_in) : undefined} // Set the minimum time based on time_in
                                  maxTime={item.break_in ? new Date(9999, 0, 1, 23, 59) : undefined} // Set a high value as the maximum time
                                />
                              </div>
                            </StyledTableData>
                            **/}
                              <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                                <div className="form-control-select">
                                  <DatePicker
                                    selected={item.time_out ? new Date(item.time_out) : null}
                                    onChange={(date) => {
                                      handleFormChange({ target: { name: "time_out", value: date } }, index);
                                    }}
                                    name="time_out"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    // value={item.time_out}
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    className="form-control date-picker"
                                    autoComplete="off"
                                    disabled={isOff || item.time_in === ""}
                                    minTime={item.break_out ? new Date(item.break_out) : new Date(item.time_in)} // Set the minimum time based on time_in
                                    maxTime={new Date(9999, 0, 1, 23, 59)} // Set a high value as the maximum time
                                  />
                                </div>
                              </StyledTableData>
                              <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                                {item.total_hours}
                              </StyledTableData>
                              <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                                <Row>
                                  <Col className="m-2" sm="3">
                                    <Button
                                      onClick={() => addFields(item.day, index)}
                                      className="btn-square btn-icon"
                                      color="primary"
                                      size="sm"
                                    >
                                      <Icon name="plus" />
                                    </Button>
                                  </Col>
                                  {item.day === "Monday" && mondayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : item.day === "Tuesday" && tuesdayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : item.day === "Wednesday" && wednesdayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : item.day === "Thursday" && thursdayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : item.day === "Friday" && fridayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : item.day === "Saturday" && saturdayCount > 1 ? (
                                    <Col className="m-2" sm="3">
                                      <Button
                                        onClick={() => removeFields(index)}
                                        className="btn-square btn-icon"
                                        color="danger"
                                        size="sm"
                                      >
                                        <Icon name="minus" />
                                      </Button>
                                    </Col>
                                  ) : // ) : item.day === "Sunday" && sundayCount > 1 ? (
                                  //   <Col className="m-2" sm="3">
                                  //     <Button
                                  //       onClick={() => removeFields(index)}
                                  //       className="btn-square btn-icon"
                                  //       color="danger"
                                  //       size="sm"
                                  //     >
                                  //       <Icon name="minus" />
                                  //     </Button>
                                  //   </Col>
                                  // ) : null}
                                  null}
                                </Row>
                              </StyledTableData>
                            </tr>
                            <tr>
                              <td colSpan="8">
                                <div className="form-control-wrap">
                                  <div className="input-group input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="inputGroup-sizing">
                                        Comments
                                      </span>
                                    </div>
                                    {/* <input
                                      name="comments"
                                      onChange={(event) => {
                                        handleFormChange(event, index);
                                      }}
                                      type="text"
                                      className="form-control"
                                    /> */}
                                    <textarea
                                      rows={3}
                                      name="comments"
                                      onChange={(event) => {
                                        handleFormChange(event, index);
                                      }}
                                      value={item.comments ? item.comments : null}
                                      className="form-control"
                                    ></textarea>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </Block>
            <div className="m-2">
              <Row className="g-4">
                <Col xl="12">
                  <Button
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={isClicked}
                    onClick={() => onAddSubmit(formFields, driverId)}
                  >
                    Create Schedule
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Content>
    </React.Fragment>
  );
};
const StyledTableHeader = styled.th`
  text-align: center;
  vertical-align: middle;
`;
const StyledTableData = styled.td`
  text-align: center;
  vertical-align: middle;
`;
export default CreateSchedule;
