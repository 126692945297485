import React, { useEffect, useRef, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { successAlert, failureAlert } from "../utils/Utils";
import SimpleBar from "simplebar-react";
import Moment from "react-moment";
import styled from "styled-components";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { role } from "../redux/userSlice";
import { toast } from "react-toastify";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  BlockBetween,
  PaginationComponent,
  SpecialTable,
  RSelect,
  DataTablePagination,
} from "../components/Component";
import {
  Button,
  Card,
  Spinner,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Input,
  ModalHeader,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import DiagnoseTrackerModal from "./components/DiagnoseTrackerModal/DiagnoseTrackerModal";
import CreateScheduleModalFinal from "./components/CreateScheduleModalFinal/CreateScheduleModalFinal";
import { user_id } from "../redux/userSlice";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backgroundImage from "../assets/images/users_background.png";
import { useForm } from "react-hook-form";

import axios from "axios";
import DataTable from "react-data-table-component";
const Drivers = () => {
  const { errors, register, handleSubmit } = useForm();
  const history = useHistory();
  const VEHICLE_TYPES = { 1: "Truck", 2: "Car", 3: "Maxi Cab", 4: "Bike", 5: "Bus" };
  const DEVICE_MODE_BADGE = { PARKED: "warning", MOVING: "success", OFFLINE: "danger" };
  const [clients, setClients] = useState([]);
  const initialTrackers = useRef([]);
  const [view, setView] = useState({
    edit: false,
    add: false,
    addMaster: false,
    diagnose: false,
  });

  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);

  const INITIAL_ADD_FORM = {
    venueRefId: String(userrole === 3 && clientValueId ? clientValueId : client_id),
    driver_first_name: "",
    driver_last_name: "",
    account_status: "Active",
  };

  const [formData, setFormData] = useState(INITIAL_ADD_FORM);
  const [addedData, setAddedData] = useState(false);
  const [EditedDriver, setEditedDriver] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [trackers, setTrackers] = useState([]);
  const [sm, updateSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [diagnoseImei, setDiagnoseImei] = useState();
  const [createdSchedule, setCreatedSchedule] = useState(false);
  const [itemPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = trackers.slice(indexOfFirstItem, indexOfLastItem);
  const [masterName, setMasterName] = useState("");

  const onViewClick = (index) => {
    console.log(index);
    console.log(currentItems[index]);
    setFormData(currentItems[index]);
  };
  const resetForm = () => {
    setFormData(INITIAL_ADD_FORM);
    onFormCancel();
    // setModalForm(false);
  };

  const toggleAddedData = () => {
    setAddedData(!addedData);
  };
  const toggleEditedDriver = () => {
    setEditedDriver(!EditedDriver);
  };
  const filterStatus = [
    { value: "Active", label: "Active" },
    { value: "Pending", label: "Pending" },
    { value: "Suspend", label: "Suspend" },
  ];
  //   const [driverList, setDriverList] = useState([]);

  const requiredFieldError = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  const onFormSubmit = (e) => {
    setIsClicked(true);
    if (formData.driver_first_name.trim() === "" || formData.driver_last_name.trim() === "") {
      requiredFieldError("Please fill all the fields!");
      setIsClicked(false);
      return;
    }
    console.log(formData);
    axios
      //.post("addDriver", formData)
      .post(`${process.env.REACT_APP_CONFIG_BASE_URL}addDriver`, formData)
      .then((res) => {
        if (res.status === 201) {
          // setData((prev) => [...prev, res.data]);
          resetForm();
          // toggleAddedData();
          getAlerts();
          successAlert("New Driver Added Successfully");
          // setAddedData(true);
        } else {
          throw new Error(res.data);
        }
      })
      .catch((err) => {
        window.alert("Error in creating alerts");
        console.log(err);
      })
      .finally(() => {
        setIsClicked(false);
      });
  };

  const onFormSubmitMaster = (e) => {
    if (masterName.trim() === "") {
      requiredFieldError("Please enter the master name!");
      return;
    }
    const payload = {
      venueRefId: userrole === 3 && clientValueId ? clientValueId : client_id,
      master_name: masterName,
    };
    axios
      .post(`${process.env.REACT_APP_CONFIG_BASE_URL}AddMaster`, payload)
      .then((res) => {
        if (res.status === 201) {
          onFormCancel();
          successAlert("New Master Added Successfully");
        } else {
          throw new Error(res.data);
        }
      })
      .catch((err) => {
        failureAlert("Please try again later");
        console.log(err);
      });
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const toggle = (type) => {
    if (type === "add") {
      setFormData(INITIAL_ADD_FORM);
    }

    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      addMaster: type === "addMaster" ? true : false,
      diagnose: type === "diagnose" ? true : false,
    });
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const onSubmitHandler = (data) => {
    axios
      .post("api/Device", { ...data, DeviceType: 1, OdometerValueNew: 0, Devicemode: "offline", client_id: client_id })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          let newTrackers = [...trackers];
          newTrackers.push(res.data);
          console.log(newTrackers);
          setTrackers(newTrackers);
          setView({
            edit: false,
            add: false,
            addMaster: false,
            diagnose: false,
          });
          successAlert("Schedule created successfully");
        } else {
          failureAlert("Error");
        }
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    // console.log(formData);
  };
  const onEditClick = (id) => {
    const selectedTracker = trackers.find((item) => item.id === id);
    setFormData({
      ...selectedTracker,
    });
  };

  const onEditSubmit = () => {
    console.log(formData);
    setIsClicked(true);
    if (formData.driver_first_name.trim() === "" || formData.driver_last_name.trim() === "") {
      requiredFieldError("Please fill all the fields!");
      setIsClicked(false);
      return;
    }

    axios
      //.put("EditDriver", formData)
      .put(`${process.env.REACT_APP_CONFIG_BASE_URL}EditDriver`, formData)
      .then((res) => {
        if (res.status === 201) {
          // console.log(newTrackers);
          // setTrackers(newTrackers);
          // toggleEditedDriver();
          getAlerts();
          setView({
            edit: false,
            add: false,
            addMaster: false,
            diagnose: false,
          });
          successAlert("Driver Updated Successfully");
        } else {
          failureAlert("Something went wrong");
        }
      })
      .catch((err) => {
        failureAlert("Something went wrong");
        console.log(err);
      })
      .finally(() => {
        setIsClicked(false);
      });
  };
  const onFormCancel = () => {
    setView({ edit: false, add: false, diagnose: false, addMaster: false });
  };
  const redirectToWidget = (id) => {
    console.log(id);
    history.push("/run", { id: id });
  };
  const deleteDriver = async (id, schedule) => {
    console.log(id);
    if (schedule) {
      failureAlert("This driver is already assigned to a schedule, you cannot delete it");
      return;
    }
    //const response = await axios.delete("DeleteDriver?id=" + id);
    const response = await axios.delete(`${process.env.REACT_APP_CONFIG_BASE_URL}DeleteDriver?id=` + id);
    if (response.status === 200) {
      successAlert("Driver Deleted Successfully");
    } else {
      failureAlert("Something went wrong");
    }
    // toggleEditedDriver();
    getAlerts();
  };
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = trackers.filter((item) => {
        return item.vehicleNo.toLowerCase().includes(onSearchText.toLowerCase());
      });

      setTrackers([...filteredObject]);
    } else {
      setTrackers([...initialTrackers.current]);
    }
  }, [onSearchText]);

  const getAlerts = async () => {
    try {
      setLoading(true);
      //const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=` + client_id);
      let response;
      if (userrole === 3 && clientValueId) {
        response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${clientValueId}`);
      } else {
        response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${client_id}`);
      }

      if (response.status === 200) {
        const filteredData = response.data.filter((driver) => driver.id);
        setTrackers([...filteredData]);
        initialTrackers.current = [...filteredData];
      } else {
        setTrackers([]);
        initialTrackers.current = [];
      }
    } catch (error) {
      setTrackers([]);
      initialTrackers.current = [];
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAlerts();
  }, [clientValueId]);
  // useEffect(() => {
  //   const getAlerts = async () => {
  //     try {
  //       //const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=` + client_id);
  //       const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=85`);
  //       const filteredData = response.data.filter((driver) => driver.id);
  //       return filteredData;
  //     } catch (error) {
  //       console.log(error);
  //       return [];
  //     }
  //   };

  //   setLoading(true);
  //   getAlerts()
  //     .then((res) => {
  //       setTrackers([...res]);
  //       initialTrackers.current = [...res];
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [addedData]);
  // useEffect(() => {
  //   const getAlerts = async () => {
  //     try {
  //       //const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=` + client_id);
  //       const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=85`);
  //       const filteredData = response.data.filter((driver) => driver.id);
  //       return filteredData;
  //     } catch (error) {
  //       console.log(error);
  //       return [];
  //     }
  //   };

  //   setLoading(true);
  //   getAlerts()
  //     .then((res) => {
  //       setTrackers([...res]);
  //       initialTrackers.current = [...res];
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [EditedDriver]);
  //   useEffect(() => {
  //     const getDriverList = async () => {
  //       const response = await axios.get("/getDriverList?venueRefId=" + client_id);
  //       return response.data;
  //     };
  //     getDriverList().then((res) => {
  //       setDriverList([...res]);
  //     });
  //   }, []);

  const [rowsPerPageS, setRowsPerPage] = useState(7);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const columns = [
    {
      name: <div style={{ margin: "0 auto" }}>{"First Name"}</div>,
      selector: (row) => (row.driver_first_name ? row.driver_first_name : "-"),
      sortable: true,
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Last Name"}</div>,
      selector: (row) => (row.driver_last_name ? row.driver_last_name : "-"),
      sortable: true,
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Status"}</div>,
      selector: (row) => row.account_status,
      // sortable: true,
      center: true,
      grow: 2,
      format: (row) => {
        return (
          <span>
            <Badge
              pill
              color={
                row.account_status === "Active" ? "success" : row.account_status === "Pending" ? "warning" : "danger"
              }
            >
              {row.account_status}
            </Badge>
          </span>
        );
      },
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Scheduled"}</div>,
      selector: (row) => row.transaction_id,
      center: true,
      format: (row) => {
        return (
          <span>
            {row.schedule ? (
              <td style={{ padding: "0.75rem 0.25rem" }}>
                <UncontrolledPopover placement="top" target="Popover1" trigger="hover">
                  <PopoverBody>Already Scheduled</PopoverBody>
                </UncontrolledPopover>
                <Icon id="Popover1" name="check-circle-fill" style={{ fontSize: "25px" }} className={"text-teal"} />
              </td>
            ) : (
              <td style={{ padding: "0.75rem 0.25rem" }}>
                <UncontrolledPopover placement="top" target="Popover2" trigger="hover">
                  <PopoverBody>Not Scheduled</PopoverBody>
                </UncontrolledPopover>
                <Icon id="Popover2" name="plus-circle-fill" style={{ fontSize: "25px" }} className={"text-primary"} />
              </td>
            )}
          </span>
        );
      },
      grow: 1.2,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Actions"}</div>,
      selector: (row) => row,
      cell: (row, index) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <UncontrolledDropdown>
            <DropdownToggle
              tag="a"
              href="#more"
              onClick={(ev) => ev.preventDefault()}
              className="dropdown-toggle btn btn-icon btn-trigger"
            >
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu right>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#more"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("edit");
                      onViewClick(index);
                    }}
                  >
                    <Icon name="edit-alt" />
                    <span>Edit</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    tag="a"
                    href="#more"
                    onClick={(ev) => {
                      ev.preventDefault();
                      deleteDriver(row.id, row.schedule);
                    }}
                  >
                    <Icon name="trash-empty" />
                    <span>Delete</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ),
    },
  ].filter((column) => column !== null);

  return (
    <React.Fragment>
      <Head title="Run Cutting"></Head>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "200px",
          paddingTop: "105px",
        }}
      >
        <BlockTitle page tag="h3">
          <Title>Drivers</Title>
        </BlockTitle>
      </div>
      <Content>
        <Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <div className="d-flex align-items-center justify-content-start">
                  <div>
                    <Button
                      className="toggle btn-icon d-md-none"
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                    <Button
                      className="toggle d-none d-md-inline-flex"
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus"></Icon>
                      <span>New Driver</span>
                    </Button>
                  </div>
                </div>
              </BlockHeadContent>
              <BlockBetween>
                <ul className="nk-block-tools g-3">
                  <li id="PopoverDismisable">
                    <Button color="light" outline className="btn-white">
                      <Icon name="user-list" />
                      <span>{currentItems.length}</span>
                    </Button>
                  </li>
                  <UncontrolledPopover placement="top" target="PopoverDismisable" trigger="hover">
                    <PopoverBody>Total Drivers</PopoverBody>
                  </UncontrolledPopover>
                </ul>
              </BlockBetween>
            </BlockBetween>
          </BlockHead>
        </Block>

        <Block>
          <div className="nk-ecwg nk-ecwg6" style={{ marginTop: "-30px" }}>
            <Block>
              {isLoading ? (
                <Card>
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <Spinner color="primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ) : (
                <DataTable
                  data={currentItems}
                  columns={columns}
                  noDataComponent={<div className="pt-5 pb-5 text-center">No Drivers Found</div>}
                  sortIcon={
                    <div>
                      <span>&darr;</span>
                      <span>&uarr;</span>
                    </div>
                  }
                  defaultSortField="transaction_date"
                  pagination
                  paginationTotalRows={currentItems.length}
                  paginationPerPage={rowsPerPageS}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                    <DataTablePagination
                      customItemPerPage={rowsPerPageS}
                      itemPerPage={rowsPerPage}
                      totalItems={rowCount}
                      paginate={onChangePage}
                      currentPage={currentPage}
                      onChangeRowsPerPage={onChangeRowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  )}
                ></DataTable>
              )}
            </Block>
          </div>
        </Block>

        {/* Below is the Diagnose Modal */}
        <Modal isOpen={view.diagnose} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <DiagnoseTrackerModal imei={diagnoseImei} />
            </div>
          </ModalBody>
        </Modal>
        {/* Below is the Create Modal*/}
        <Modal isOpen={view.add} toggle={() => onFormCancel()} className="modal-dialog-centered" size="m">
          <ModalHeader
            close={
              <a href="#cancel" className="close">
                <Icon
                  name="cross-sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                ></Icon>
              </a>
            }
          >
            Add Driver
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                  <label className="form-label" htmlFor="first-name">
                    First Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="driver_first_name"
                      name="driver_first_name"
                      value={formData.driver_first_name}
                      onChange={onChangeHandler}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-label" htmlFor="last-name">
                    Last Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="driver_last_name"
                      name="driver_last_name"
                      value={formData.driver_last_name}
                      onChange={onChangeHandler}
                    />
                  </div>
                </FormGroup>
                {/* <FormGroup>
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={filterStatus}
                      defaultValue={{
                        value: "Active",
                        label: "Active",
                      }}
                      onChange={(e) => setFormData({ ...formData, account_status: e.value })}
                    />
                  </div>
                </FormGroup> */}

                <FormGroup>
                  <Button color="primary" type="submit" disabled={isClicked} size="lg">
                    Add Driver
                  </Button>
                </FormGroup>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={view.addMaster} toggle={() => onFormCancel()} className="modal-dialog-centered" size="m">
          <ModalHeader
            close={
              <a className="close">
                <Icon
                  name="cross-sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                ></Icon>
              </a>
            }
          >
            Add Master
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              <form onSubmit={handleSubmit(onFormSubmitMaster)}>
                <FormGroup>
                  <label className="form-label" htmlFor="masterName">
                    Master Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="masterName"
                      name="masterName"
                      value={masterName}
                      onChange={(e) => setMasterName(e.target.value)}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button color="primary" type="submit" size="lg">
                    Add Master
                  </Button>
                </FormGroup>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={view.edit} toggle={() => onFormCancel()} className="modal-dialog-centered" size="m">
          <ModalHeader
            close={
              <a href="#cancel" className="close">
                <Icon
                  name="cross-sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                ></Icon>
              </a>
            }
          >
            Edit Driver
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              <form onSubmit={handleSubmit(onEditSubmit)}>
                <FormGroup>
                  <label className="form-label" htmlFor="first-name">
                    First Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="driver_first_name"
                      name="driver_first_name"
                      value={formData.driver_first_name}
                      onChange={onChangeHandler}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-label" htmlFor="last-name">
                    Last Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="driver_last_name"
                      name="driver_last_name"
                      value={formData.driver_last_name}
                      onChange={onChangeHandler}
                    />
                  </div>
                </FormGroup>
                {/* <FormGroup>
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <select
                      className="form-control"
                      value={formData.account_status}
                      onChange={(e) => setFormData({ ...formData, account_status: e.target.value })}
                    >
                      {filterStatus.map((status) => (
                        <option key={status.value} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGroup> */}

                <FormGroup>
                  <Button color="primary" type="submit" size="lg" disabled={isClicked}>
                    Edit Driver
                  </Button>
                </FormGroup>
              </form>
            </div>
          </ModalBody>
        </Modal>
        {/* Below is the add tracker modal */}

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
    </React.Fragment>
  );
};

const Title = styled.h3`
  font-size: 60px;
  font-weight: 900;
  padding-left: 32px;

  @media (max-width: 768px) {
    font-size: 40px;
    padding: 20px;
  }
`;

export default Drivers;
