import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import HeaderSearch from "../header-search/HeaderSearch";
import ChatDropdown from "./dropdown/chat/Chat";
import { Icon } from "../../components/Component";
import { Button, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col } from "reactstrap";
import { RSelect } from "../../components/Component";
import axios from "axios";
import { username, role, user_id } from "../../redux/userSlice";

import { useDispatch } from "react-redux";
import { sessionClientSaveValue, sessionClientSaveLabel, selectSavedValue, selectSavedLabel, fetchClientInfo } from "../../redux/sessionClientSlice";
import { io } from 'socket.io-client';
import { toast } from "react-toastify";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [clientList, setClientList] = useState([]);
  const fullscreenRef = useRef(document.documentElement);
  const userrole = useSelector(role);
  const client_id = useSelector(user_id);
  const dispatch = useDispatch();
  const clientValue = useSelector(selectSavedValue);
  const clientLabel = useSelector(selectSavedLabel);

  // const [selectedOption, setSelectedOption] = useState({ value: "", label: "Select Client" }); // State to store the selected option
  const [selectedOption, setSelectedOption] = useState({ value: 0, label: "" });
  const agency = sessionStorage.getItem("AgencyUpdated")

  useEffect(() => {
    //Call and set initial client value and label
    if (!(localStorage.getItem("value") && localStorage.getItem("label")) || localStorage.getItem("paneltext") !== `${process.env.REACT_APP_PANEL_TEXT}`) {
      dispatch(fetchClientInfo()).then(result => {
        if (result.type === 'getClientInfo/fulfilled') {
          const { clientid, clientname } = result.payload;
          //Update states with session storage values
          setSelectedOption({ value: clientid, label: clientname });
          dispatch(sessionClientSaveValue(clientid));
          dispatch(sessionClientSaveLabel(clientname));
        } else {
          console.error('Failed to fetch client info');
        }
      });
    }
    else {
      //Update states with session storage values
      setSelectedOption({ value: localStorage.getItem("value"), label: localStorage.getItem("label") });
      dispatch(sessionClientSaveValue(localStorage.getItem("value")));
      dispatch(sessionClientSaveLabel(localStorage.getItem("label")));
    }

    const fullscreenChangeHandler = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (agency) {
      setSelectedOption({ value: localStorage.getItem("value"), label: localStorage.getItem("label") });
      dispatch(sessionClientSaveValue(localStorage.getItem("value")));
      dispatch(sessionClientSaveLabel(localStorage.getItem("label")));

      sessionStorage.clear();
    }
  }, [agency])

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      fullscreenRef.current.requestFullscreen().catch((err) => {
        console.error("Failed to enable fullscreen mode", err);
      });
    } else {
      document.exitFullscreen();
    }
  };

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  const handleClient = (selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    // Dispatch action to save value
    dispatch(sessionClientSaveValue(selectedOption.value));
    //Update session storage value
    localStorage.setItem('value', selectedOption.value);

    // Dispatch action to save label
    dispatch(sessionClientSaveLabel(selectedOption.label));
    //Update session storage label
    localStorage.setItem('label', selectedOption.label);

  };

  //Session Storage handling
  // useEffect(() => {
  //   //Fetch client id and names dynamically
  // const fetchData = async () => {
  //   if (!localStorage.getItem('value') && !localStorage.getItem('label')) {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}config`);
  //       const data = response.data;

  //       //Get enabled client id and name of enabled agency
  //       const foundObject = data.find(obj => obj.smart_venues);

  //       if (foundObject) {
  //         localStorage.setItem('value', foundObject.clientid);
  //         localStorage.setItem('label', foundObject.clientname);
  //         console.log("Client id and name:", localStorage.getItem("value"), localStorage.getItem("label"));
  //         setSelectedOption({ value: localStorage.getItem("value"), label: localStorage.getItem("label") });
  //         dispatch(sessionClientSaveValue(localStorage.getItem("value")));
  //         dispatch(sessionClientSaveLabel(localStorage.getItem("label")));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }
  //   else {
  //     //Update states beased on session storage values
  //     setSelectedOption({ value: localStorage.getItem("value"), label: localStorage.getItem("label") });
  //     dispatch(sessionClientSaveValue(localStorage.getItem("value")));
  //     dispatch(sessionClientSaveLabel(localStorage.getItem("label")));
  //   }
  // };

  //   fetchData();
  // }, []);

  useEffect(() => {
    async function getListOfClients() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}config`);
        const filteredClients = response.data.filter((client) => client.status === 1); // Filtering clients where status is true
        const organizedData = filteredClients.map((client) => ({
          value: client.clientid,
          label: client.clientname,
        }));
        console.log(organizedData);
        setClientList(organizedData);
      } catch (error) {
        console.error("Error fetching clients", error);
      }
    }

    getListOfClients();
  }, []);

  const CustomToast = ({ body }) => {
    return (
      <div className="toastr-text">
        <h5>Ticket Validation</h5>
        <p>{body}</p>
      </div>
    );
  };

  const validationAlert = (value) => {
    toast.success(<CustomToast body={value} />, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };


  const showNotification = (title, body) => {
    //Alert
    let ticketValue;
    if (body.length === 1) {
      ticketValue = "Ticket" + body[0] + " has been validated";
    }
    else {
      ticketValue = "Tickets " + body + " have been validated";
    }
    if (!document.hidden) {
      validationAlert(ticketValue);
    }

    // Check if notifications are supported
    if (!('Notification' in window)) {
      console.console('This browser does not support desktop notifications.');
      return;
    }

    console.log("Notification permission", Notification.permission);

    // Check current permission status
    if (Notification.permission === "granted") {
      // Permission granted: Show the notification
      new Notification(title, { body: ticketValue });
    } else if (Notification.permission === "default") {
      // Permission not yet requested: Ask the user
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification(title, { body: ticketValue });
        } else {
          console.log('User denied notification permission.');
        }
      });
    } else {
      // Permission explicitly d;enied
      console.log('Notifications permission was denied previously.');
    }
  };


  //Websocket
  useEffect(() => {
    if (process.env.REACT_APP_PANEL_TEXT === "ZIG") {
      const socketPath = 'websocket';
      const eventName = 'ticket_validation';
      const socket = io(`https://zig-zig-data.zed-admin.com/${socketPath}`);

      // Handle socket connection
      socket.on('connect', () => {
        console.log(`Connected to ${socketPath}`);
      });

      // Listen for events
      socket.on(eventName, (data) => {
        console.log(`Data from event ${eventName}:`, JSON.stringify(data, null, 2));
        const responseData = data.data;
        let clientIDMatches, matchedValue;
        const clientID = localStorage.getItem("clientid");
        const clientVALUE = localStorage.getItem("value");

        if (userrole === 3) {
          clientIDMatches = responseData.some(item => item.ClientId === parseInt(clientVALUE));
          matchedValue = responseData.find(item => item.ClientId === parseInt(clientVALUE));
          console.log("Client id matches", clientIDMatches);
        }
        else {
          clientIDMatches = responseData.some(item => item.ClientId === parseInt(clientID));
          matchedValue = responseData.find(item => item.ClientId === parseInt(clientID));
          console.log("Client id matches", clientIDMatches);
        }

        if (clientIDMatches && matchedValue && matchedValue.ticketids.length > 0) {
          showNotification("Ticket Validation", matchedValue.ticketids);
        }

      });

      // Handle socket disconnection
      socket.on('disconnect', () => {
        console.log('Disconnected from /items');
      });

      // Clean up the socket connection when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
  }, []);

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap" style={window.innerWidth > 768 ? { marginLeft: "-20px" } : {}}>
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <Logo />
          </div>
          <div className="nk-header-search ml-3 ml-xl-0">
            {/* <HeaderSearch />
             */}
            {userrole === 3 && (
              <Col sm={6}>
                {clientList.length > 0 && (
                  <RSelect options={clientList} onChange={handleClient} value={selectedOption} />
                )}
              </Col>
            )}
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* <li className="chats-dropdown hide-mb-xs" onClick={() => setVisibility(false)}>
                <ChatDropdown />
              </li> */}
              {/* <li className="notification-dropdown mr-n1" onClick={() => setVisibility(false)}>
                <RSelect options={defaultOptions} />
              </li> */}

              {/* Show analytics only for MDOT super admin */}
              {(userrole === 3 || userrole === 2) && (
                <li className="user-dropdown d-xl-block d-lg-block d-none">
                  {process.env.REACT_APP_PANEL_TEXT === "MDOT" ? (
                    <Button
                      className="btn"
                      color="secondary"
                      onClick={() =>
                        // window.open("https://zig-trip.com/MDOTAnalytics/loginreport?fromurl=true", "_blank")
                        window.open("https://mdot-analytics.netlify.app/", "_blank")
                      }
                    >
                      Analytics
                    </Button>
                  ) : (
                    <Button
                      className="btn"
                      color="secondary"
                      onClick={() => window.open("https://rta-analytics.netlify.app/", "_blank")}
                    >
                      Analytics
                    </Button>
                  )}
                </li>
              )}
              {(userrole === 3 || userrole === 2) && (
                <li className="user-dropdown d-xl-block d-lg-block d-none">
                  <Button
                    className="btn"
                    color="secondary"
                    onClick={() => window.open("https://beacon.zusan.in/", "_blank")}
                  >
                    Hardware Analytics
                  </Button>
                </li>
              )}
              <li className="notification-dropdown mr-n1" onClick={() => setVisibility(false)}>
                <DropdownToggle
                  tag="a"
                  href="#dropdown"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleFullScreen();
                  }}
                  className="nk-quick-nav-icon"
                >
                  <div className="icon-status icon-status-na">
                    {isFullScreen ? <Icon name="minimize"></Icon> : <Icon name="maximize"></Icon>}
                  </div>
                </DropdownToggle>
              </li>
              <li className="user-dropdown" onClick={() => setVisibility(false)}>
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;