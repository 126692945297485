import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";
import { useSelector } from "react-redux";
import { selectHomepage } from "../redux/userSlice";
import AddTracker from "../pages/AddTracker";
import FleetTracker from "../pages/FleetTracker";
import TrackerInfo from "../pages/TrackerInfo";
import VehicleInfo from "../pages/VehicleInfo";
import ScheduleInfo from "../pages/ScheduleInfo";
import AlertManage from "../pages/AlertManage";
import home from "../pages/home";
import EvolutionConfig from "../pages/EvolutionConfig";
import AllVenues from "../pages/AllVenues";
import Attendance from "../pages/Attendance";
import tickets from "../pages/tickets";
import UserSettings from "../pages/UserSettings";
import RunCut from "../pages/RunCut";
import RunCutting from "../pages/RunCutting";
import DriverSchedule from "../pages/DriverSchedule";
import drivers from "../pages/drivers";
import paymentDevices from "../pages/Devices";
// import register1 from "../pages/register1";

import CreateSchedule from "../pages/CreateSchedule";
import EditSchedule from "../pages/EditSchedule";
import MasterSchedule from "../pages/MasterSchedule";
import addMaster from "../pages/addMaster";
import MasterDriver from "../pages/MasterDriver";
import MasterRunCut from "../pages/MasterRunCut";
import CreateMasterSchedule from "../pages/CreateMasterSchedule";
import EditMasterSchedule from "../pages/EditMasterSchedule";
import LaunchSite from "../pages/LaunchSite";
import editBuscSchedule from "../pages/editBuscSchedule";
import Feedback from "../pages/Feedback";
import ZIGAIAnalytics from "../pages/ZIGAIAnalytics";
import Beverages from "../pages/Beverages";
import TicketPurchasesCategory from "../pages/TicketPurchasesCategory";

// const FleetTracker = React.lazy(() => import("../pages/FleetTracker"));
const AssetManagement = React.lazy(() => import("../pages/AssetManagement"));
const Homepage = React.lazy(() => import("../pages/Devices"));
const AddNewDevice = React.lazy(() => import("../pages/AddNewDevice"));
const DeviceConfig = React.lazy(() => import("../pages/DeviceConfig"));
const GTFS = React.lazy(() => import("../pages/GTFS"));
const ClientSetup = React.lazy(() => import("../pages/ClientSetup"));
const Beveragecounter1 = React.lazy(() => import("../pages/Beveragecounter1"));
const Ticketspage = React.lazy(() => import("../pages/Ticketspage"));
const AddValidator = React.lazy(() => import("../pages/AddValidator"));
const addBeverages = React.lazy(() => import("../pages/addBeverages"));
const TransitData = React.lazy(() => import("../pages/TransitData"));
const ClientsNew = React.lazy(() => import("../pages/ClientsNew"));
const TransitApproval = React.lazy(() => import("../pages/TransitApproval"));
const MuseumData = React.lazy(() => import("../pages/MuseumData"));
const Alerts = React.lazy(() => import("../pages/Alerts"));
const Documentation = React.lazy(() => import("../pages/Documentation"));
const rideinfotwo = React.lazy(() => import("../pages/rideinfotwo"));
const Payment = React.lazy(() => import("../pages/Payment"));
const RefundHistory = React.lazy(() => import("../pages/RefundHistory"));
const Transactions = React.lazy(() => import("../pages/Transactions"));
const Transactions2 = React.lazy(() => import("../pages/Transactionstwo"));
const support = React.lazy(() => import("../pages/support"));
const visitorhistory = React.lazy(() => import("../pages/visitorhistory"));
const counterlist = React.lazy(() => import("../pages/counterlist"));
const visitors = React.lazy(() => import("../pages/visitors"));
const visitorsvip = React.lazy(() => import("../pages/Visitors_Vip"));
const users = React.lazy(() => import("../pages/users"));
const Analytics = React.lazy(() => import("../pages/Analytics"));
const LimaAnalytics = React.lazy(() => import("../pages/LimaAnalytics"));
const VehicleSetup = React.lazy(() => import("../pages/VehicleSetup"));
const DeviceSetup = React.lazy(() => import("../pages/DeviceSetup"));
const addfares = React.lazy(() => import("../pages/addfares"));

const Form = React.lazy(() => import("../pages/Form"));
const SystemMap = React.lazy(() => import("../pages/SystemMap"));
const Testpage = React.lazy(() => import("../pages/Testpage"));
const FarePage = React.lazy(() => import("../pages/FareTable"));
const NewValidator = React.lazy(() => import("../pages/newValidator"));
const Riders = React.lazy(() => import("../pages/RidersAnalytics"));
const GenerateTicket = React.lazy(() => import("../pages/generateTicket"));
const Crudform = React.lazy(() => import("../pages/Crudform"));
const Rewards = React.lazy(() => import("../pages/Rewards"));
const AlertsPage = React.lazy(() => import("../pages/AlertsPage"));
const AddDevice = React.lazy(() => import("../pages/addClientDevice"));
const MicroTicketspage = React.lazy(() => import("../pages/MicroTicketPage"));
const AllTicketspage = React.lazy(() => import("../pages/AllTickets"));
const TollAnalytics = React.lazy(() => import("../pages/TollAnalytics"));
const AddNewUsers = React.lazy(() => import("../pages/addNewUsers"));
const UnActivatedTicket = React.lazy(() => import("../pages/unActivatedTickets"));
const visitorcoach = React.lazy(() => import("../pages/visitorHistoryCoach"));
const purchasefare = React.lazy(() => import("../pages/PurchaseByFare"));
const RevenueReport = React.lazy(() => import("../pages/RiderRevenueReport"));
const MonthlyReport = React.lazy(() => import("../pages/MonthlyReport"));
const BankDepositsSummary = React.lazy(() => import("../pages/BankDepositsSummary"));
const WalletTransaction = React.lazy(() => import("../pages/WalletTransactions"));
const FirmwareOta = React.lazy(() => import("../pages/FirmwareOta"));
const userLog = React.lazy(() => import("../pages/UserLogs"));
const ValidatorV2 = React.lazy(() => import("../pages/AddValidatorV2"));
const InRangeUser = React.lazy(() => import("../pages/InRangeUsers"));
const TicketBooking = React.lazy(() => import("../pages/MicroTicketBooking"));
const OpenLoopPayments = React.lazy(() => import("../pages/OpenLoopPayments"));
const BikeShareTransactions = React.lazy(() => import("../pages/BikeShareTransactions"));
const WebScrapper = React.lazy(() => import("../pages/WebScrapper"));
const newRevenueRep = React.lazy(() => import("../pages/NewRevenueRep"));

// const EvolutionConfig = React.lazy(() => import("../pages/EvolutionConfig"));
const Pages = ({ currentAccess }) => {
  const homepage = useSelector(selectHomepage) || currentAccess[0];
  const rolesRoutePages = [
    { page: 1, pathname: "devices", component: Homepage },
    { page: 2, pathname: "add-device", component: AddNewDevice },
    { page: 3, pathname: "gtfs", component: GTFS },
    { page: 4, pathname: "clients", component: ClientsNew },
    { page: 5, pathname: "client-setup", component: ClientSetup },
    { page: 6, pathname: "Beverage-c1", component: Beveragecounter1 },
    { page: 7, pathname: "Tickets", component: Ticketspage },
    { page: 8, pathname: "add-validator", component: AddValidator },
    { page: 9, pathname: "add-beverages", component: addBeverages },
    { page: 10, pathname: "transit-data", component: TransitData },
    { page: 11, pathname: "transit-approval", component: TransitApproval },
    { page: 12, pathname: "museum-data", component: MuseumData },
    { page: 13, pathname: "run-cutting", component: Alerts },
    { page: 14, pathname: "asset-management", component: AssetManagement },
    { page: 15, pathname: "documentation", component: Documentation },
    { page: 16, pathname: "add-tracker", component: AddTracker },
    { page: 29, pathname: "fleet-tracker", component: FleetTracker },
    { page: 17, pathname: "Payment", component: Payment },
    // { page: 18, pathname: "beverage-history", component: Transactions },
    { page: 19, pathname: "transactions", component: Transactions2 },
    { page: 78, pathname: "RefundHistory", component: RefundHistory },
    { page: 20, pathname: "support", component: support },
    { page: 21, pathname: "visitorhistory", component: visitorhistory },
    { page: 22, pathname: "counterlist", component: counterlist },
    { page: 23, pathname: "visitors", component: visitors },
    { page: 24, pathname: "visitorsvip", component: visitorsvip },
    { page: 25, pathname: "users", component: users },
    { page: 26, pathname: "analytics", component: Analytics },
    { page: 27, pathname: "vehicle-setup", component: VehicleSetup },
    { page: 28, pathname: "device-setup", component: DeviceSetup },
    { page: 30, pathname: "bus-schedules", component: AlertManage },
    { page: 31, pathname: "home", component: home },
    { page: 32, pathname: "evo-config", component: EvolutionConfig },
    { page: 33, pathname: "all-venues", component: AllVenues },
    { page: 34, pathname: "attendance", component: Attendance },
    { page: 35, pathname: "tickets-booth", component: tickets },
    { page: 41, pathname: "lima-analytics", component: LimaAnalytics },
    { page: 20, pathname: "addfares", component: addfares },
    { page: 20, pathname: "Form", component: Form },
    { page: 46, pathname: "system-map", component: SystemMap },
    { page: 36, pathname: "Testpage", component: Testpage },
    { page: 36, pathname: "faretable", component: FarePage },
    { page: 44, pathname: "newvalidator", component: NewValidator },
    { page: 43, pathname: "newdevice", component: AddDevice },
    { page: 38, pathname: "generateticket", component: GenerateTicket },
    { page: 37, pathname: "riders-analytics", component: Riders },
    { page: 39, pathname: "Crudform", component: Crudform },
    { page: 45, pathname: "rewards", component: Rewards },
    { page: 40, pathname: "alertpage", component: AlertsPage },
    { page: 42, pathname: "microtickets", component: MicroTicketspage },
    { page: 47, pathname: "AllTickets", component: AllTicketspage },
    { page: 48, pathname: "TollAnalytics", component: TollAnalytics },
    { page: 50, pathname: "AllUsers", component: AddNewUsers },
    { page: 61, pathname: "RiderRevenueReport", component: RevenueReport },
    { page: 62, pathname: "monthlyreport", component: MonthlyReport },
    { page: 63, pathname: "depositsummary", component: BankDepositsSummary },
    { page: 60, pathname: "purchase-fare", component: purchasefare },
    { page: 53, pathname: "UnActivated", component: UnActivatedTicket },
    { page: 56, pathname: "coachdata-history", component: visitorcoach },
    { page: 59, pathname: "wallet-transactions", component: WalletTransaction },
    { page: 65, pathname: "firmware-ota", component: FirmwareOta },
    { page: 66, pathname: "addvalidator-v2", component: ValidatorV2 },
    { page: 67, pathname: "inrange-users", component: InRangeUser },
    { page: 77, pathname: "userlogs", component: userLog },
    { page: 68, pathname: "Feedback", component: Feedback },
    { page: 88, pathname: "ZIGAIAnalytics", component: ZIGAIAnalytics },
    { page: 89, pathname: "Beverages", component: Beverages },
    { page: 69, pathname: "microticketsBooking", component: TicketBooking },
    { page: 70, pathname: "New-Revenue-Report", component: newRevenueRep },
    { page: 90, pathname: "OpenLoopPayments", component: OpenLoopPayments },
    { page: 91, pathname: "bikeshare-payments", component: BikeShareTransactions },
    { page: 92, pathname: "data-extract", component: WebScrapper },
    { page: 96, pathname: "purchase-category", component: TicketPurchasesCategory },

    { page: 30, pathname: "run", component: RunCut },
    { page: 30, pathname: "run-cutting-scheduler", component: RunCutting },
    { page: 30, pathname: "driver-schedule", component: DriverSchedule },
    { page: 30, pathname: "drivers", component: drivers },
  ];
  //RunCutting;
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path={"/paymentDevices"} component={paymentDevices} />
        <Route path={`/launch-site`} component={LaunchSite} />
        <Route path={`/editSchedule`} component={editBuscSchedule} />
        <Route path={`/create-schedule`} component={CreateSchedule} />
        <Route path={`/create-ms-schedule`} component={CreateMasterSchedule} />
        <Route path={`/edit-master-schedule`} component={EditMasterSchedule} />
        <Route path={`/edit-schedule`} component={EditSchedule} />
        <Route path={`/run`} component={RunCut} />
        <Route path={`/master-run-cutting`} component={MasterRunCut} />
        {/* <Route path={`/run-cutting-scheduler`} component={RunCutting} />
        <Route path={`/driver-schedule`} component={DriverSchedule} />
        <Route path={`/routes`} component={drivers} /> */}
        <Route path={`/tracker-info`} component={TrackerInfo} />
        <Route path={`/vehicle-info`} component={VehicleInfo} />
        <Route path={`/schedule-info`} component={ScheduleInfo} />
        <Route path={`/user-profile-setting`} component={UserSettings} />
        <Route path={`/create-master-schedule`} component={MasterSchedule} />
        <Route path={`/create-master`} component={addMaster} />
        <Route path={`/master-driver`} component={MasterDriver} />
        <Route path={`${process.env.PUBLIC_URL}/device/:id`} component={DeviceConfig}></Route>
        {rolesRoutePages.map(
          (route) =>
            /* Page 27 made static below and at Menu.js line 282. Make sure to remove at both places*/
            [...currentAccess, 30].includes(route.page) && (
              <Route
                key={route.page}
                page={route.page}
                exact
                path={`${process.env.PUBLIC_URL}/${homepage === route.page ? "" : route.pathname}`}
                component={route.component}
              ></Route>
            )
        )}
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
