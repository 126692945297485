import React, { useState, useEffect, useRef } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import exportFromJSON from "export-from-json";
import "moment-timezone";
import Moment from "react-moment";
import moment from "moment";
import "./total.css";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import styled from "styled-components";
import backgroundImage from "../assets/images/transaction_background.png";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Button,
  DataTablePagination,
  PreviewCard,
} from "../components/Component";
import { user_id } from "../redux/userSlice";
import {
  Card,
  Spinner,
  Badge,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { username, role } from "../redux/userSlice";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import axios from "axios";
import { format } from "url";

const Export = ({ data, startDate, endDate, filteredItems, clientValueLabel, user, userrole, logoUrl }) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  const [modal, setModal] = useState(false);

  const exportNoDataAlert = () => {
    toast.error("There is no data available for export!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
  };

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 3000);
    }
  }, [modal]);

  const fileName = `Ticket Purchases by Category [${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}]`;
  const exportCSV = (filteredItems) => {
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const csvData = [];
    // Push headers to CSV data
    csvData.push(["User Name/Email", "ID", "Description", "Debit", "Credit", "Balance", "Transaction Date"]);

    // Push row data to CSV data
    filteredItems.forEach((item) => {
      const rowData = [
        item.username !== null && item.username !== "" ? item.username : "-",
        item.ticketid !== null && item.ticketid !== ""
          ? item.ticketid === 0
            ? item.transactionid
            : item.ticketid
          : "-",
        // item.amount !== null ? '$' + Math.abs(parseFloat(item.amount || "0")).toFixed(2) : "-",
        item.description !== null && item.description !== "" ? item.description : "-",
        item.debited !== null && item.debited !== "" ? "- $" + parseFloat(item.debited).toFixed(2) : "-",
        item.credited !== null && item.credited !== "" ? "$" + parseFloat(item.credited).toFixed(2) : "-",
        item.balance !== null && item.balance !== "" ? "$" + parseFloat(item.balance).toFixed(2) : "-",
        item.transactiondate !== null && item.transactiondate !== ""
          ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ];
      csvData.push(rowData);
    });

    // Convert CSV data to string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Download CSV file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportExcel = (filteredItems) => {
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const exportType = exportFromJSON.types.xls;
    const modifiedData = filteredItems.map((item) => ({
      "User Name/Email": item.username !== null && item.username !== "" ? item.username : "-",
      ID:
        item.ticketid !== null && item.ticketid !== ""
          ? item.ticketid === 0
            ? item.transactionid
            : item.ticketid
          : "-",
      // "Amount": item.amount !== null ? '$' + Math.abs(parseFloat(item.amount || "0")).toFixed(2) : "-",
      Description: item.description !== null && item.description !== "" ? item.description : "-",
      Debit: item.debited !== null && item.debited !== "" ? "- $ " + parseFloat(item.debited).toFixed(2) : "-",
      Credit: item.credited !== null && item.credited !== "" ? "$ " + parseFloat(item.credited).toFixed(2) : "-",
      Balance: item.balance !== null && item.balance !== "" ? "$ " + parseFloat(item.balance).toFixed(2) : "-",
      "Transaction Date":
        item.transactiondate !== null && item.transactiondate !== ""
          ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
          : "-",
    }));
    exportFromJSON({ data: modifiedData, fileName: fileName, exportType });
  };

  const exportPDF = (filteredItems) => {
    if (filteredItems.length === 0) {
      exportNoDataAlert();
      return;
    }
    const doc = new jsPDF("landscape");

    const logo = new Image();
    logo.crossOrigin = "anonymous";
    logo.src = logoUrl;

    logo.onload = () => {
      const imgWidth = 30;
      const imgHeight = 30;
      const logoX = doc.internal.pageSize.width - imgWidth - 10;
      const logoY = 2;
      doc.addImage(logo, "JPEG", logoX, logoY, imgWidth, imgHeight);

      doc.setFont("times", "bold");
      doc.setFontSize(20);
      doc.setTextColor(255, 0, 0);
      const textValue = userrole === 3 ? clientValueLabel : user;
      doc.text(textValue, 10, 20);

      doc.setFont("times", "normal");
      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text("Wallet Transaction Data", 10, 40);

      const columnHeaders = ["User Name/Email", "ID", "Description", "Debit", "Credit", "Balance", "Transaction Date"];
      const tableData = filteredItems.map((item) => [
        item.username !== null && item.username !== "" ? item.username : "-",
        item.ticketid !== null && item.ticketid !== ""
          ? item.ticketid === 0
            ? item.transactionid
            : item.ticketid
          : "-",
        // item.amount !== null ? '$' + Math.abs(parseFloat(item.amount || "0")).toFixed(2) : "-",
        item.description !== null && item.description !== "" ? item.description : "-",
        item.debited !== null && item.debited !== "" ? "- $" + parseFloat(item.debited).toFixed(2) : "-",
        item.credited !== null && item.credited !== "" ? "$" + parseFloat(item.credited).toFixed(2) : "-",
        item.balance !== null && item.balance !== "" ? "$" + parseFloat(item.balance).toFixed(2) : "-",
        item.transactiondate !== null && item.transactiondate !== ""
          ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ]);
      doc.autoTable({
        head: [columnHeaders],
        body: tableData,
        startY: 50,
      });
      doc.save(fileName + ".pdf");
    };

    logo.onerror = () => {
      console.error("Failed to load logo image");

      const columnHeaders = ["User Name/Email", "ID", "Description", "Debit", "Credit", "Balance", "Transaction Date"];
      const tableData = filteredItems.map((item) => [
        item.username !== null && item.username !== "" ? item.username : "-",
        item.ticketid !== null && item.ticketid !== ""
          ? item.ticketid === 0
            ? item.transactionid
            : item.ticketid
          : "-",
        // item.amount !== null ? '$' + Math.abs(parseFloat(item.amount || "0")).toFixed(2) : "-",
        item.description !== null && item.description !== "" ? item.description : "-",
        "- $" + parseFloat(item.debited).toFixed(2),
        "$" + parseFloat(item.credited).toFixed(2),
        "$" + parseFloat(item.balance).toFixed(2),
        item.transactiondate !== null && item.transactiondate !== ""
          ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
          : "-",
      ]);
      doc.autoTable({
        head: [columnHeaders],
        body: tableData,
      });
      doc.save(fileName + ".pdf");
    };
  };

  return (
    <React.Fragment>
      <div className="dt-export-buttons d-flex align-center" style={{ marginTop: -20 }}>
        <div className="dt-export-title d-none d-md-inline-block">Export</div>
        <div className="dt-buttons btn-group flex-wrap">
          <button
            className="btn btn-secondary buttons-csv buttons-html5"
            type="button"
            onClick={() => exportCSV(filteredItems)}
          >
            <span>CSV</span>
          </button>{" "}
          <button
            className="btn btn-secondary buttons-excel buttons-html5"
            type="button"
            onClick={() => exportExcel(filteredItems)}
          ></button>{" "}
          <button
            className="btn btn-secondary buttons-pdf buttons-html5"
            type="button"
            onClick={() => exportPDF(filteredItems)}
          >
            <span>Pdf</span>
          </button>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};
const TicketPurchasesCategory = () => {
  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);
  const user = useSelector(username);
  const logoUrl = process.env.REACT_APP_SIGN_IN;
  const date = new Date();
  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - 7);

  const [rangeDate, setRangeDate] = useState({
    start: daysAgo,
    end: new Date(),
  });

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
  };

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       if (rangeDate.start && rangeDate.end) {
  //         setLoading(true);
  //         try {
  //           setData([]);
  //           const startDate =
  //             rangeDate.start.getFullYear() + "-" + (rangeDate.start.getMonth() + 1) + "-" + rangeDate.start.getDate();
  //           const endDate =
  //             rangeDate.end.getFullYear() + "-" + (rangeDate.end.getMonth() + 1) + "-" + rangeDate.end.getDate();

  //           let url;
  //           if (userrole === 3 && clientValueId) {
  //             url = `${process.env.REACT_APP_CONFIG_BASE_URL}getIndividualWallet/transactions?Startdate=${startDate}&Enddate=${endDate}`;
  //           } else {
  //             url = `${process.env.REACT_APP_CONFIG_BASE_URL}getIndividualWallet/transactions?Startdate=${startDate}&Enddate=${endDate}`;
  //           }
  //           const response = await fetch(url);
  //           setLoading(false);
  //           if (response.status === 200) {
  //             const json = await response.json();

  //             console.log("data", json);
  //             initialData.current = [...json.data];
  //             if (onSearchText === "") {
  //               setData(json.data);
  //             } else {
  //               const filteredObject = data.filter((item) => {
  //                 const formattedAmount =
  //                   item.amount !== null && item.amount !== "" ? parseFloat(item.amount).toFixed(2).toString() : "";
  //                 const formattedCreditAmount =
  //                   item.credited !== null && item.credited !== "" ? parseFloat(item.credited).toFixed(2).toString() : "";
  //                 const formattedDebitAmount =
  //                   item.debited !== null && item.debited !== "" ? parseFloat(item.debited).toFixed(2).toString() : "";
  //                 const formattedTransDate =
  //                   item.transactiondate !== null && item.transactiondate !== ""
  //                     ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
  //                     : "";
  //                 return (
  //                   (item.username !== null &&
  //                     item.username !== "" &&
  //                     item.username.toLowerCase().includes(onSearchText.toLowerCase())) ||
  //                   (item.description !== null &&
  //                     item.description !== "" &&
  //                     item.description.toLowerCase().includes(onSearchText.toLowerCase())) ||
  //                   (item.ticketid !== null && item.ticketid !== "" && item.ticketid.toString().includes(onSearchText)) ||
  //                   formattedAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //                   formattedCreditAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //                   formattedDebitAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //                   // formattedTransDate.toLowerCase().includes(onSearchText.toLowerCase())
  //                   formattedTransDate
  //                     .toLowerCase()
  //                     .split(" ")
  //                     .join("")
  //                     .includes(onSearchText.toLowerCase().split(" ").join(""))
  //                 );
  //               });
  //               console.log(filteredObject);
  //               setData(filteredObject);
  //             }
  //           }
  //         } catch (err) {
  //           setError(true);
  //         }
  //       }
  //     };
  //     fetchData();
  //     return () => {
  //       const controller = new AbortController();
  //       controller.abort();
  //     };
  //   }, [rangeDate, client_id, clientValueId]);

  //   useEffect(() => {
  //     if (onSearchText !== "") {
  //       const filteredObject = initialData.current.filter((item) => {
  //         const formattedAmount =
  //           item.amount !== null && item.amount !== "" ? parseFloat(item.amount).toFixed(2).toString() : "";
  //         const formattedCreditAmount =
  //           item.credited !== null && item.credited !== "" ? parseFloat(item.credited).toFixed(2).toString() : "";
  //         const formattedDebitAmount =
  //           item.debited !== null && item.debited !== "" ? parseFloat(item.debited).toFixed(2).toString() : "";
  //         const formattedTransDate =
  //           item.transactiondate !== null && item.transactiondate !== ""
  //             ? moment(item.transactiondate).format("MMMM Do YYYY, h:mm a")
  //             : "";
  //         return (
  //           (item.username !== null &&
  //             item.username !== "" &&
  //             item.username.toLowerCase().includes(onSearchText.toLowerCase())) ||
  //           (item.description !== null &&
  //             item.description !== "" &&
  //             item.description.toLowerCase().includes(onSearchText.toLowerCase())) ||
  //           (item.ticketid !== null && item.ticketid !== "" && item.ticketid.toString().includes(onSearchText)) ||
  //           formattedAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //           formattedCreditAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //           formattedDebitAmount.toLowerCase().includes(onSearchText.toLowerCase()) ||
  //           // formattedTransDate.toLowerCase().includes(onSearchText.toLowerCase())
  //           formattedTransDate.toLowerCase().split(" ").join("").includes(onSearchText.toLowerCase().split(" ").join(""))
  //         );
  //       });
  //       setData(filteredObject);
  //     } else {
  //       setData([...initialData.current]);
  //     }
  //     setCurrentPage(1);
  //   }, [onSearchText]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Last Week");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (value) {
      case "Today":
        startDate = today;
        endDate = today;
        break;
      case "Last Week":
        startDate.setDate(today.getDate() - 7);
        break;
      //   case "Last Month":
      //     startDate.setMonth(today.getMonth() - 1);
      //     break;
      //   case "Last 3 Months":
      //     startDate.setMonth(today.getMonth() - 3);
      //     break;
      default:
        break;
    }
    setRangeDate({ start: startDate, end: endDate });
  };

  const [activeTab, setActiveTab] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [dates, setDates] = useState([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        let response;
        const startDate =
          rangeDate.start.getFullYear() + "-" + (rangeDate.start.getMonth() + 1) + "-" + rangeDate.start.getDate();
        const endDate =
          rangeDate.end.getFullYear() + "-" + (rangeDate.end.getMonth() + 1) + "-" + rangeDate.end.getDate();

        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseFareCountNew?startDate=${startDate}&endDate=${endDate}&client_id=${clientValueId}`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}GetDaywiseFareCountNew?startDate=${startDate}&endDate=${endDate}&client_id=${client_id}`
          );
        }

        if (response.status !== 200) {
          setNoData(true);
          setApiData([]);
          return;
        } else {
          setNoData(false);
        }

        const { Dates, Data } = response.data;
        setDates(Dates);
        setApiData(Data);
        setActiveTab(Data[0]?.CategoryName || null);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
        setNoData(true);
        setApiData([]);
      } finally {
        // setInitialLoading(false);
      }
    };
    fetchVehicleData();
  }, [rangeDate, clientValueId]);

  const [rowsPerPageS, setRowsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <React.Fragment>
      <Head title="Ticket Purchases"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h2">
                Ticket Purchases by Category
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              {/* <label htmlFor="startDate">Date Range</label> */}
              <div className="d-flex align-items-end" style={{ marginTop: "10px", marginBottom: 5 }}>
                <div style={{ textAlign: "right" }}>
                  <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="dropdown-container">
                    <DropdownToggle
                      color="primary"
                      className="dropdown-toggle"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                    >
                      <Icon style={{ paddingRight: "5px" }} name="calender-date" />
                      {selectedOption}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                      <DropdownItem onClick={() => handleDropdownChange("Today")}>Today</DropdownItem>
                      <DropdownItem onClick={() => handleDropdownChange("Last Week")}>Last Week</DropdownItem>
                      {/* <DropdownItem onClick={() => handleDropdownChange("Last Month")}>Last Month</DropdownItem> */}
                      {/* <DropdownItem onClick={() => handleDropdownChange("Last 3 Months")}>Last 3 Months</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="ml-2">
                  <BlockTitle page tag="h3">
                    <DatePicker
                      selected={rangeDate.start}
                      startDate={rangeDate.start}
                      onChange={onRangeChange}
                      endDate={rangeDate.end}
                      selectsRange
                      className="form-control date-picker"
                    />
                  </BlockTitle>
                </div>
              </div>
              {/* <div className="toggle-wrap nk-block-tools-toggle mt-4">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li style={{ width: "80%" }}>
                      <div className="form-control-wrap" style={{ width: "80%" }}>
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by email"
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </BlockHeadContent>

            <BlockHeadContent>
              {/* <div className="datatable-filter" style={{ marginBottom: 10, marginTop: 35 }}>
                <div className="d-flex justify-content-end g-2">
                  <Export
                    data={data}
                    filteredItems={data}
                    startDate={rangeDate.start}
                    endDate={rangeDate.end}
                    clientValueLabel={clientValueLabel}
                    user={user}
                    userrole={userrole}
                    logoUrl={logoUrl}
                  />
                </div>
              </div> */}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {noData ? (
            <PreviewCard className={"text-center"}>
              <b>No Data Found</b>
            </PreviewCard>
          ) : (
            <PreviewCard>
              <Row className="g-gs">
                <Col md={3}>
                  <ul className="nav link-list-menu border border-light round m-0">
                    {apiData.map((category) => (
                      <li key={category.CategoryName}>
                        <a
                          href="#tab"
                          className={classnames({ active: activeTab === category.CategoryName })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setActiveTab(category.CategoryName);
                          }}
                        >
                          <span>{category.CategoryName}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col md={9}>
                  {apiData.map(
                    (category) =>
                      activeTab === category.CategoryName && (
                        <div key={category.CategoryName}>
                          <h4>{category.CategoryName}</h4>
                          <DataTable
                            columns={[
                              {
                                name: <div style={{ margin: "0 auto", textAlign: "center" }}>Date</div>,
                                selector: (row) => row.date,
                                sortable: true,
                                sortFunction: (a, b) => {
                                  const dateA = new Date(a.date.split("/").reverse().join("-"));
                                  const dateB = new Date(b.date.split("/").reverse().join("-"));
                                  return dateA - dateB;
                                },
                                center: true,
                              },
                              ...apiData[0].FareNameCounts.map((fare, index) => ({
                                name: <div style={{ margin: "0 auto", textAlign: "center" }}>{fare[0]}</div>,
                                selector: (row) => row.fareCounts[index] || 0,
                                sortable: true,
                                center: true,
                              })),
                            ]}
                            data={dates.map((date, i) => ({
                              date,
                              fareCounts: apiData[0].FareNameCounts.map((fare) => fare[i + 1] || 0),
                            }))}
                            defaultSortField="date"
                            defaultSortAsc={false}
                            noDataComponent={<div className="pt-5 pb-5 text-center">No data found</div>}
                            sortIcon={
                              <div>
                                <span>&darr;</span>
                                <span>&uarr;</span>
                              </div>
                            }
                            pagination
                            paginationTotalRows={dates && dates.length}
                            paginationPerPage={rowsPerPageS}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            paginationComponent={({
                              currentPage,
                              rowsPerPage,
                              rowCount,
                              onChangePage,
                              onChangeRowsPerPage,
                            }) => (
                              <DataTablePagination
                                customItemPerPage={rowsPerPageS}
                                itemPerPage={rowsPerPage}
                                totalItems={rowCount}
                                paginate={onChangePage}
                                currentPage={currentPage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                              />
                            )}
                          />
                        </div>
                      )
                  )}
                </Col>
              </Row>
            </PreviewCard>
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TicketPurchasesCategory;
