import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Spinner,
  CardLink,
  CardText,
  CardSubtitle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { successAlert, failureAlert } from "../utils/Utils";
import styled from "styled-components";
import backgroundImage from "../assets/images/fleet_tracking.png";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  BlockBetween,
  PaginationComponent,
  Col,
  ReactDataTable,
  Button,
  Row,
} from "../components/Component";
import { Link } from "react-router-dom";

import { useLocation, Redirect } from "react-router-dom";
import Content from "../layout/content/Content";
import Moment from "react-moment";
import moment from "moment";

import axios from "axios";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { role, user_id } from "../redux/userSlice";

import Head from "../layout/head/Head";
import Select from "react-select";
import MyDropDown from "./MyDropDown";
import WeeklyDatePicker from "./WeeklyDatePicker";
import { useSelector } from "react-redux";
const DriverSchedule = () => {
  const history = useHistory();
  const [view, setView] = useState(false);

  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };
  const [updatedDate, setUpdatedDate] = useState(new Date());

  const [sm, updateSm] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [selectedStatus, setSelectedStatus] = useState(() => {
    const state = history.location.state;
    return state && state.selectedDay ? state.selectedDay : days[new Date().getDay()];
  });

  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);

  const driverId = 1;
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const getSchedules = async () => {
      try {
        // const day = moment(updatedDate).format("dddd");
        //const response = await axios.get("getSchedulesbasedDays?day=" + day);
        let response;
        if (userrole === 3 && clientValueId) {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}v2/getSchedulesbasedDays?venueRefId=${clientValueId}&day=` +
              selectedStatus
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}v2/getSchedulesbasedDays?venueRefId=${client_id}&day=` +
              selectedStatus
          );
        }
        //Super admin handling
        if (response.status === 200) {
          const responseData = response.data.filter(
            (item) => !item.coach_no.includes("Day Off") && item.coach_no !== ""
          );
          setData([...responseData]);
        } else {
          setData([]);
        }
      } catch (err) {
        console.log("Error message:", err);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    getSchedules();
  }, [selectedStatus, clientValueId]);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedStatus]);

  const redirectToRunCuttingPage = () => {
    console.log(data);
    history.push("/editSchedule", { data: data, selectedDay: selectedStatus });
  };
  return (
    <React.Fragment>
      <Head title="Run Cutting"></Head>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "200px",
          paddingTop: "105px",
        }}
      >
        <BlockTitle page tag="h3">
          <Title>Route Schedule Board</Title>
        </BlockTitle>
      </div>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              {/* <BlockTitle page tag="h3">
                Route Schedule Board
              </BlockTitle> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                      <Button
                        color="primary"
                        className="mr-4"
                        onClick={() => redirectToRunCuttingPage()}
                        disabled={data.length === 0}
                        style={{
                          pointerEvents: data.length === 0 ? "none" : "auto",
                          opacity: data.length === 0 ? 0.5 : 1,
                        }}
                      >
                        <Icon name="edit" className="mr-0.5"></Icon>
                        <span>Edit Schedule</span>
                      </Button>
                    </li> */}
                    {/* <li className="nk-block-tools-opt">
                      <BlockHeadContent>
                        <Link to={`${process.env.PUBLIC_URL}/run-cutting-scheduler`}>
                          <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                            <Icon name="arrow-left"></Icon>
                            <span>Back</span>
                          </Button>
                          <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                            <Icon name="arrow-left"></Icon>
                          </Button>
                        </Link>
                      </BlockHeadContent>
                    </li> */}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card>
            <CardBody className="card-inner">
              {/* <MyDropDown /> */}

              {/* <div className="form-control-wrap" style={{ width: '120px' }}>
                <DatePicker selected={updatedDate} onChange={setUpdatedDate} className="form-control date-picker" />{" "}
              </div> */}
              <div className="form-control-wrap" style={{ width: "120px" }}>
                <UncontrolledDropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle outline color="primary" className="dropdown-toggle dropdown-indicator btn-dim  flex">
                    Day: {selectedStatus === "all" ? "All" : selectedStatus}
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-check">
                      <li>
                        <span>Select day</span>
                      </li>
                      <li className={selectedStatus === "all" && "active"} onClick={() => setSelectedStatus("all")}>
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          All
                        </a>
                      </li>
                      {/* <li
                        className={selectedStatus === "Sunday" && "active"}
                        onClick={() => setSelectedStatus("Sunday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Sunday
                        </a>
                      </li> */}
                      <li
                        className={selectedStatus === "Monday" && "active"}
                        onClick={() => setSelectedStatus("Monday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Monday
                        </a>
                      </li>
                      <li
                        className={selectedStatus === "Tuesday" && "active"}
                        onClick={() => setSelectedStatus("Tuesday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Tuesday
                        </a>
                      </li>
                      <li
                        className={selectedStatus === "Wednesday" && "active"}
                        onClick={() => setSelectedStatus("Wednesday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Wednesday
                        </a>
                      </li>
                      <li
                        className={selectedStatus === "Thursday" && "active"}
                        onClick={() => setSelectedStatus("Thursday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Thursday
                        </a>
                      </li>
                      <li
                        className={selectedStatus === "Friday" && "active"}
                        onClick={() => setSelectedStatus("Friday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Friday
                        </a>
                      </li>
                      <li
                        className={selectedStatus === "Saturday" && "active"}
                        onClick={() => setSelectedStatus("Saturday")}
                      >
                        <a href="#links" onClick={(ev) => ev.preventDefault()}>
                          Saturday
                        </a>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <br></br>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <StyledTableHeader style={{ textAlign: "center", verticalAlign: "middle" }} scope="col">
                      Driver Name
                    </StyledTableHeader>
                    <StyledTableHeader style={{ textAlign: "center", verticalAlign: "middle" }} scope="col">
                      Coach No
                    </StyledTableHeader>
                    <StyledTableHeader style={{ textAlign: "center", verticalAlign: "middle" }} scope="col">
                      Line Name
                    </StyledTableHeader>

                    <StyledTableHeader scope="col">Time In</StyledTableHeader>
                    {/* <StyledTableHeader scope="col">Break</StyledTableHeader>
                    <StyledTableHeader scope="col">Break</StyledTableHeader> */}
                    <StyledTableHeader scope="col">Time Out</StyledTableHeader>
                    <StyledTableHeader scope="col">Total Hours</StyledTableHeader>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner className="m-2" color="primary" />
                      </td>
                    </tr>
                  ) : data.length > 0 ? (
                    data.map((item) => {
                      return (
                        <React.Fragment>
                          <tr>
                            <th style={{ textAlign: "center", verticalAlign: "middle" }} scope="row">
                              {item.driver_name}
                            </th>
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {item.coach_no === "off" || item.coach_no === "" || item.coach_no === "Select"
                                ? "Off"
                                : item.coach_no}
                            </StyledTableData>
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {item.line_no === "" || item.line_no === "Select" ? "Off" : item.line_no}
                            </StyledTableData>
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              <b className="text-primary">
                                {item.time_in === "" ? "Off" : <Moment format="hh:mm a">{item.time_in}</Moment>}
                              </b>
                            </StyledTableData>
                            {/* <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {item.break_in === "" ? "Off" : <Moment format="hh:mm a">{item.break_in}</Moment>}
                            </StyledTableData>
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {item.break_out === "" ? "Off" : <Moment format="hh:mm a">{item.break_out}</Moment>}
                            </StyledTableData> */}
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              <b className="text-primary">
                                {item.time_out === "" ? "Off" : <Moment format="hh:mm a">{item.time_out}</Moment>}
                              </b>
                            </StyledTableData>
                            <StyledTableData style={{ textAlign: "center", verticalAlign: "middle" }}>
                              {item.total_hours}
                            </StyledTableData>
                          </tr>
                          <tr style={item.comments ? { color: "#1270F2", fontWeight: 800 } : {}}>
                            <td colSpan="8" className="text-center">
                              {item.comments ? item.comments : "No Comments"}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No Schedules Added
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

const Title = styled.h3`
  font-size: 60px;
  font-weight: 900;
  padding-left: 32px;
`;

const StyledTableHeader = styled.th`
  text-align: center;
  vertical-align: middle;
`;
const StyledTableData = styled.td`
  text-align: center;
  vertical-align: middle;
`;
const StyledTableRow = styled.tr`
  text-align: center;
  vertical-align: middle;
`;

export default DriverSchedule;
