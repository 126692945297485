import React, { useEffect, useRef, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { successAlert, failureAlert } from "../utils/Utils";
import SimpleBar from "simplebar-react";
import Moment from "react-moment";
import styled from "styled-components";

import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  BlockBetween,
  PaginationComponent,
  SpecialTable,
  DataTablePagination,
} from "../components/Component";
import {
  Button,
  Card,
  Spinner,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Input,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import DiagnoseTrackerModal from "./components/DiagnoseTrackerModal/DiagnoseTrackerModal";
import CreateScheduleModalFinal from "./components/CreateScheduleModalFinal/CreateScheduleModalFinal";
import { user_id } from "../redux/userSlice";
import { selectSavedValue, selectSavedLabel } from "../redux/sessionClientSlice";
import { role } from "../redux/userSlice";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backgroundImage from "../assets/images/schedules.png";

import axios from "axios";
import DataTable from "react-data-table-component";
const RunCutting = () => {
  const history = useHistory();
  const VEHICLE_TYPES = { 1: "Truck", 2: "Car", 3: "Maxi Cab", 4: "Bike", 5: "Bus" };
  const DEVICE_MODE_BADGE = { PARKED: "warning", MOVING: "success", OFFLINE: "danger" };
  const [clients, setClients] = useState([]);
  const initialTrackers = useRef([]);
  const [view, setView] = useState({
    edit: false,
    add: false,
    diagnose: false,
  });
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [trackers, setTrackers] = useState([]);
  const [sm, updateSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [diagnoseImei, setDiagnoseImei] = useState();
  const [createdSchedule, setCreatedSchedule] = useState(false);
  const [itemPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = trackers.slice(indexOfFirstItem, indexOfLastItem);
  const client_id = useSelector(user_id);
  const clientValueId = useSelector(selectSavedValue);
  const clientValueLabel = useSelector(selectSavedLabel);
  const userrole = useSelector(role);

  //   const [driverList, setDriverList] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      diagnose: type === "diagnose" ? true : false,
    });
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const onSubmitHandler = (data) => {
    axios
      .post("api/Device", { ...data, DeviceType: 1, OdometerValueNew: 0, Devicemode: "offline", client_id: client_id })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          let newTrackers = [...trackers];
          newTrackers.push(res.data);
          console.log(newTrackers);
          setTrackers(newTrackers);
          setView({
            edit: false,
            add: false,
            diagnose: false,
          });
          successAlert("Schedule created successfully");
        } else {
          failureAlert("Test");
        }
      });
  };

  const onEditClick = (id) => {
    const selectedTracker = trackers.find((item) => item.id === id);
    setFormData({
      ...selectedTracker,
    });
  };

  const closeModal = () => {
    setView({
      edit: false,
      add: false,
      diagnose: false,
    });
  };
  const onEditSubmit = (data, driverId) => {
    console.log(data);
    let js = { driver_id: driverId, schedules: data };
    // console.log(updatedDate);
    // const updatedVehicle = {
    //   id: data.id,
    //   coach_no: data.Coachno,
    //   route_name: data.routeno,
    //   driver_name: data.Odometer,
    //   schedule_date: updatedDate,
    // };
    console.log(data.length);
    console.log(js);
    axios
      .post(`${process.env.REACT_APP_CONFIG_BASE_URL}addschedule`, js)
      .then((res) => {
        if (res.status === 201) {
          const newTrackers = [...trackers];
          newTrackers.push(js);
          // const editedIdx = newTrackers.findIndex((item) => item.id === data.id);
          // newTrackers[editedIdx] = { ...data };
          // setTrackers(newTrackers);
          console.log(newTrackers);
          setTrackers(newTrackers);
          // setCreatedSchedule(true);
          getAlerts();
          setView({
            edit: false,
            add: false,
            diagnose: false,
          });
          successAlert("Schedule created successfully");
        } else {
          failureAlert("Please select a driver");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFormCancel = () => {
    setView({ edit: false, add: false, diagnose: false });
  };
  const redirectToWidget = (id) => {
    console.log(id);
    history.push("/run", { id: id });
  };
  const redirectToCreateSchedulePage = () => {
    history.push("/create-schedule");
  };
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = trackers.filter((item) => {
        return item.vehicleNo.toLowerCase().includes(onSearchText.toLowerCase());
      });

      setTrackers([...filteredObject]);
    } else {
      setTrackers([...initialTrackers.current]);
    }
  }, [onSearchText]);

  const getAlerts = async () => {
    try {
      setLoading(true);
      //const response = await axios.get("getDriverList?venueRefId=" + client_id);
      let response;
      if (userrole === 3 && clientValueId) {
        response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${clientValueId}`);
      } else {
        response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getDriverList?venueRefId=${client_id}`);
      }

      if (response.status === 200) {
        const filteredData = response.data.filter((driver) => driver.schedule);
        setTrackers([...filteredData]);
        initialTrackers.current = [...filteredData];
      } else {
        setTrackers([]);
        initialTrackers.current = [];
      }
    } catch (error) {
      setTrackers([]);
      initialTrackers.current = [];
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAlerts();
  }, [clientValueId]);

  //   useEffect(() => {
  //     const getDriverList = async () => {
  //       const response = await axios.get("/getDriverList?venueRefId=" + client_id);
  //       return response.data;
  //     };
  //     getDriverList().then((res) => {
  //       setDriverList([...res]);
  //     });
  //   }, []);

  const [rowsPerPageS, setRowsPerPage] = useState(7);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const columns = [
    {
      name: <div style={{ margin: "0 auto" }}>{"First Name"}</div>,
      selector: (row) => (row.driver_first_name ? row.driver_first_name : "-"),
      sortable: true,
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Last Name"}</div>,
      selector: (row) => (row.driver_last_name ? row.driver_last_name : "-"),
      sortable: true,
      grow: 1,
      center: true,
    },
    {
      name: <div style={{ margin: "0 auto" }}>{"Actions"}</div>,
      selector: (row) => row,
      cell: (row) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Icon
            name="eye"
            className={"text-primary"}
            onClick={(ev) => {
              ev.preventDefault();
              localStorage.setItem("driverName", row.driver_first_name + " " + row.driver_last_name);
              redirectToWidget(row.id);
            }}
          />
        </div>
      ),
    },
  ].filter((column) => column !== null);

  return (
    <React.Fragment>
      <Head title="Run Cutting"></Head>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: "200px",
          paddingTop: "105px",
        }}
      >
        <BlockTitle page tag="h3">
          <Title>Bus Schedules</Title>
        </BlockTitle>
      </div>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <Col>
                <Button
                  className="toggle btn-icon d-md-none"
                  color="primary"
                  onClick={() => {
                    toggle("add");
                  }}
                >
                  <Icon name="plus"></Icon>
                </Button>
                <Button
                  className="toggle d-none d-md-inline-flex"
                  color="primary"
                  onClick={() => {
                    redirectToCreateSchedulePage();
                  }}
                >
                  <Icon name="plus"></Icon>
                  <span>Create Schedule</span>
                </Button>
              </Col>
            </BlockHeadContent>
            <BlockHeadContent>
              <div style={{ marginTop: "10px" }} className="toggle-expand-content expanded">
                <ul className="nk-block-tools g-3">
                  <li id="PopoverDismisable">
                    <Button color="light" outline className="btn-white">
                      <Icon name="map" />
                      <span>{currentItems.length}</span>
                    </Button>
                  </li>
                  <UncontrolledPopover placement="top" target="PopoverDismisable" trigger="hover">
                    <PopoverBody>Total Schedules</PopoverBody>
                  </UncontrolledPopover>
                </ul>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-ecwg nk-ecwg6" style={{ marginTop: "-30px" }}>
            <Block>
              {isLoading ? (
                <Card>
                  <div className="nk-ecwg nk-ecwg6">
                    <div className="card-inner">
                      <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                          <Spinner color="primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ) : (
                <DataTable
                  data={currentItems}
                  columns={columns}
                  noDataComponent={<div className="pt-5 pb-5 text-center">No Drivers Found</div>}
                  sortIcon={
                    <div>
                      <span>&darr;</span>
                      <span>&uarr;</span>
                    </div>
                  }
                  defaultSortField="transaction_date"
                  pagination
                  paginationTotalRows={currentItems.length}
                  paginationPerPage={rowsPerPageS}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                    <DataTablePagination
                      customItemPerPage={rowsPerPageS}
                      itemPerPage={rowsPerPage}
                      totalItems={rowCount}
                      paginate={onChangePage}
                      currentPage={currentPage}
                      onChangeRowsPerPage={onChangeRowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  )}
                ></DataTable>
              )}
            </Block>
          </div>
        </Block>

        {/* Below is the Diagnose Modal */}
        <Modal isOpen={view.diagnose} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a href="#cancel" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <DiagnoseTrackerModal imei={diagnoseImei} />
            </div>
          </ModalBody>
        </Modal>
        {/* Below is the Create Modal*/}
        <Modal isOpen={view.add} toggle={() => onFormCancel()} className="modal-dialog-centered" size="xl">
          <ModalBody>
            <a href="#cancel" className="close">
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <CreateScheduleModalFinal
                onSubmitHandler={closeModal}
                isEdit={false}
                // formData={formData}
                clients={clients}
              />
            </div>
          </ModalBody>
        </Modal>
        {/* Below is the add tracker modal */}

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}
      </Content>
    </React.Fragment>
  );
};

const Title = styled.h3`
  font-size: 60px;
  font-weight: 900;
  padding-left: 32px;

  @media (max-width: 768px) {
    font-size: 40px;
    padding: 20px;
  }
`;

export default RunCutting;
